import {Component, EventEmitter, Input, Output, QueryList} from '@angular/core';
import {get} from 'lodash-es';
import {JumioPageFilter} from 'shared/components/table/dto/jumio-page-filter';
import {JumioSort} from 'shared/components/table/dto/jumio-sort';
import {IconConstants} from 'shared/services/icon.constants';
import {DataTableColumnDirective} from '../data-table-column.directive';
import {ToggleAll} from '../toggle/toggle.helper';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less']
})
export class HeaderComponent {
  @Input() public columns: QueryList<DataTableColumnDirective> | undefined;
  @Input() public filter: JumioPageFilter | undefined;

  @Output() public sortEvent = new EventEmitter<JumioSort>();
  @Output() public toggleAll = new EventEmitter<ToggleAll>();
  @Output() public toggleCurrentPage = new EventEmitter<ToggleAll>();

  public readonly Icons = IconConstants.ICONS;

  public changeSort(sort: JumioSort): void {
    this.sortEvent.emit(sort);
  }

  public changeToggleAll(toggleAll: ToggleAll): void {
    this.toggleAll.emit(toggleAll);
  }

  public changeToggleCurrentPage(toggle: ToggleAll): void {
    this.toggleCurrentPage.emit(toggle);
  }

  public getSortIcon(colSort: string): string {
    // Column is not sortable
    if (!colSort) {
      return '';
    }

    // Column is sortable and currently selected
    if (colSort === get(this.filter, 'sort.field')) {
      return get(this.filter, 'sort.asc') ? this.Icons.ARROW_DOWN : this.Icons.ARROW_UP;
    }

    // Column is sortable but not currently selected
    return '';
  }

  public isToggleSelected(toggle: ToggleAll): boolean {
    return toggle.status === true || toggle.status === undefined;
  }
}
