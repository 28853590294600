import {ContentChild, Directive, Input, TemplateRef} from '@angular/core';
import {ToggleAll} from './toggle/toggle.helper';

@Directive({selector: '[appDataTableColumn]'})
export class DataTableColumnDirective {
  @Input() public name: string | undefined;
  @Input() public colWidth: number | undefined;
  @Input() public colRatio = 1;
  @Input() public sort: string | undefined;
  @Input() public toggleAll: ToggleAll | undefined | null;
  @Input() public toggleCurrentPage: ToggleAll | undefined;
  @ContentChild(TemplateRef, {read: TemplateRef}) public templateRef: TemplateRef<any> | undefined;

  public calculatedPercentage: number | undefined;
}
