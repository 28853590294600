import moment from 'moment';

export const getAssetsUrl = (pathToAsset: string, deployUrl: string, environment: {production: boolean} = {production: false}): string => {
  if (!pathToAsset) {
    return '';
  }

  return environment.production ? deployUrl + pathToAsset : pathToAsset;
};

export const formatDateWithoutTimeZoneConversion = (date: string, format: string): string => {
  if (!date || !format) {
    return '';
  }

  return moment(new Date(date)).utc(false).format(format);
};
