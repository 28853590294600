import {ISars} from '../monitor.interface';

export const sarsFiledResponseMock: ISars = {
  data: {
    total: 12,
    categories: [
      {
        name: 'filed',
        items: [
          {
            name: 'AML-003276',
            value: 'Filed 20 days ago'
          },
          {
            name: 'AML-003984',
            value: 'Filed 18 days ago'
          },
          {
            name: 'AML-004833',
            value: 'Filed 12 days ago'
          },
          {
            name: 'AML-113276',
            value: 'Filed 65 days ago'
          },
          {
            name: 'AML-113984',
            value: 'Filed 78 days ago'
          },
          {
            name: 'AML-114833',
            value: 'Filed 56 days ago'
          },
          {
            name: 'AML-223276',
            value: 'Filed 14 days ago',
            tags: [
              {
                name: 'STRUCTURING',
                type: 'structuring'
              },
              {
                name: 'FLOW-THROUGH',
                type: 'flow'
              }
            ]
          },
          {
            name: 'AML-223984',
            value: 'Filed 18 days ago',
            tags: [
              {
                name: 'DORMANT PARTY',
                type: 'dormant'
              },
              {
                name: 'FLOW-THROUGH',
                type: 'flow'
              }
            ]
          },
          {
            name: 'AML-224833',
            value: 'Filed 90 days ago',
            tags: [
              {
                name: 'STRUCTURING',
                type: 'structuring'
              },
              {
                name: 'FLOW-THROUGH',
                type: 'flow'
              }
            ]
          },
          {
            name: 'AML-334833',
            value: 'Filed 66 days ago',
            tags: [
              {
                name: 'STRUCTURING',
                type: 'structuring'
              },
              {
                name: 'FLOW-THROUGH',
                type: 'flow'
              }
            ]
          }
        ]
      }
    ]
  },
  context: {
    aggregatorFormat: 'day',
    monitorDataType: 'com.jumio.dashboardeventsaggregator.domain.CategoryMonitor',
    dimensionDataType: 'java.lang.Long',
    seriesDataType: 'java.lang.Double',
    startTime: 1661385600000,
    endTime: 1661731200000,
    historyStartTime: 1629849600000,
    historyEndTime: 1630195200000,
    durationInaggregatorFormat: 4,
    processedStartTime: 1663640878564,
    processedEndTime: 1663640880372,
    processedTime: 1808
  }
};
