import {ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, Optional} from '@angular/core';
import {NgModelGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import {isEmpty} from 'lodash-es';
import {FormGroupCustomValidatorsInput} from 'public-shared/components/form-group/abstract/abstract-form-group.component';
import {AbstractFormGroupInputComponent} from 'public-shared/components/form-group/form-group-input/abstract-form-group-input.component';

@Component({
  selector: 'app-form-group-input',
  templateUrl: './form-group-input.component.html',
  styleUrls: ['./form-group-input.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormGroupInputComponent),
      multi: true
    }
  ]
})
export class FormGroupInputComponent extends AbstractFormGroupInputComponent {
  @Input() public popoverTemplate: any;
  @Input() public autocomplete: string | undefined;

  // Custom validators can be defined here
  @Input() public validators: FormGroupCustomValidatorsInput = {} as FormGroupCustomValidatorsInput;

  // Additional comment for an input (e.g. validation instructions). Can be empty.
  @Input() public inputComment: any;

  // Placeholder was disabled completely by default. Adding this flag for backward compatibility
  @Input() public showPlaceholder = false;
  @Input() public override placeholder = ' ';
  @Input() public classes: string | undefined;

  constructor(private cd: ChangeDetectorRef, @Optional() modelGroup: NgModelGroup) {
    super(modelGroup);
  }

  // IE11 Workaround - focus has to be set manually
  public onInputClick(event: any): void {
    if (event && event.target && event.target.focus) {
      event.target.focus();
    }
  }

  public override writeValue(newValue: string | number): void {
    super.writeValue(newValue);
    this.cd.markForCheck();
  }

  public get labelHasError(): boolean | null | undefined {
    return !isEmpty(this.model?.errors) && this.model?.touched;
  }

  public get placeholderText(): string {
    return this.showPlaceholder ? this.placeholder : ' ';
  }
}
