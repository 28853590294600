<div class="error-page">
  <jtm-navigation-J4></jtm-navigation-J4>

  <div class="error-container">
    <img class="error-img" src="./assets/403.svg" />
    <div class="error-title">403</div>
    <div class="error-msg">Sorry, but you do not have permission to access this page</div>
    <jtm-button label="Return to homepage" [variant]="btnVariant" (clicked)="redirectToHomePage()"></jtm-button>
  </div>
</div>
