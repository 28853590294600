import {Component, EventEmitter, HostListener, Input, Output, ViewChild} from '@angular/core';
import {ModalDirective, ModalModule, ModalOptions} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.less'],
  standalone: true,
  imports: [ModalModule]
})
/**
 * The base component which contains all modal windows in the app.
 */
export class ModalComponent {
  /**
   * The title of the modal window.
   */
  @Input() public title: string | undefined;

  /**
   * Close modal on Esc down
   */
  @Input() public closeOnEsc: boolean | undefined;

  /**
   * Emits a new event when the modal window has been closed with the button on the top left corner.
   * @type {EventEmitter<any>}
   */
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() public onClose: EventEmitter<any> = new EventEmitter<any>();

  /**
   * The reference of the modal window view child, can be a directive or another ModalComponent.
   */
  @ViewChild('modal') public modal: ModalDirective | ModalComponent | undefined;

  public isOpen: boolean | undefined;

  /**
   * The configuration options for the modal window.
   * @type {{backdrop: string}}
   */
  public modalOptions: ModalOptions = {
    backdrop: 'static',
    keyboard: true,
    focus: true
  };

  /**
   * Calls the "show" method of the modal window.
   */
  public show(): void {
    this.modal?.show();
    this.isOpen = true;
  }

  /**
   * Calls the "hide" method of the modal window.
   */
  public hide(): void {
    this.modal?.hide();
    this.isOpen = false;
  }

  /**
   * Calls the "hide" method of the modal window and emits a new "closed" event.
   */
  public close(): void {
    this.modal?.hide();
    this.onClose.emit();
  }

  @HostListener('window:keydown.esc', ['$event'])
  public osEscDown(event: any): void {
    event.preventDefault();

    if (this.isOpen && this.closeOnEsc) {
      this.close();
    }
  }
}
