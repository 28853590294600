import {InjectionToken} from '@angular/core';

export const FF_BASE_API_URL = new InjectionToken<string>('FF_BASE_API_URL');
export const FeatureFlagsOptions = [
  // UP feature Flags
  'settings-users-advanced-filter'
  // CP feature Flags
  // AP feature Flags
] as const;

export type FeatureFlag = (typeof FeatureFlagsOptions)[number];
export type FeatureFlags = Record<FeatureFlag, boolean>;
