import {INewCustomers} from '../monitor.interface';

export const newCustomersResponseMock: INewCustomers = {
  data: {
    dimensions: [1656633600000, 1659312000000, 1661990400000],
    categories: [
      {
        name: 'customer',
        values: [148, 325, 10],
        series: [
          {
            name: 'PASSED',
            total: 167,
            percentage: 0.34575569358178054,
            values: [23, 144, 0]
          },
          {
            name: 'REJECTED',
            total: 292,
            percentage: 0.6045548654244306,
            values: [122, 160, 20]
          },
          {
            name: 'WARNING',
            total: 24,
            percentage: 0.049689440993788817,
            values: [6, 42, 0]
          }
        ]
      }
    ],
    total: {
      value: 483,
      percentage: 483
    },
    average: {
      value: 241,
      percentage: 241
    }
  },
  context: {
    aggregatorFormat: 'month',
    monitorDataType: 'com.jumio.dashboardeventsaggregator.domain.CategoryMonitor',
    dimensionDataType: 'java.lang.Long',
    seriesDataType: 'java.lang.Double',
    startTime: 1657497600000,
    endTime: 1662681600000,
    historyStartTime: 1625961600000,
    historyEndTime: 1631145600000,
    durationInaggregatorFormat: 2,
    processedStartTime: 1662777312143,
    processedEndTime: 1662777314893,
    processedTime: 2750
  }
};
