<app-modal *ngIf="!isSSOUser" [title]="'Authorization'" #modal (onClose)="resetForm()">
  <div>
    <div class="modal-message">{{ message }}</div>
    <form #form="ngForm">
      <app-form-group-input
        class="modal-input"
        data-cy="password-modal-input"
        [errorMessages]="errorMessages"
        type="password"
        name="password"
        placeholder="Password"
        [(ngModel)]="password"
        [required]="true"
      ></app-form-group-input>
      <app-form-group-input
        class="modal-input"
        data-cy="password-modal-tfa-input"
        *ngIf="isTfaEnabled"
        [errorMessages]="errorMessages"
        type="number"
        name="passCode"
        placeholder="Security code"
        [(ngModel)]="passCode"
        [required]="isTfaEnabled"
      ></app-form-group-input>
    </form>
  </div>
  <div class="modal-footer">
    <button data-cy="password-modal-cancel-button" type="button" class="button pull-left" (click)="closeModal()">Cancel</button>
    <app-loading-button
      class="pull-right"
      data-cy="password-modal-submit-button"
      [disabled]="!!(!password || (isTfaEnabled && !passCode))"
      [isPending]="isPending"
      type="button"
      (onClick)="submitPassword()"
    >
      Submit
    </app-loading-button>
  </div>
</app-modal>

<app-modal-confirm *ngIf="isSSOUser" title="Save Confirmation" confirmText="Confirm" (onConfirm)="submit()" #modal>
  {{ message }}
</app-modal-confirm>
