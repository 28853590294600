import {Injectable} from '@angular/core';
import {EnvironmentProvider} from 'public-shared/services/environment-provider';
import {environment} from './environment';
import {J4WelcomeEnvironmentData} from './environment.prod';

@Injectable()
export class WelcomeEnvironmentProvider extends EnvironmentProvider<J4WelcomeEnvironmentData> {
  public get environment(): J4WelcomeEnvironmentData {
    return environment;
  }
}
