import {HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpParams, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {JtmSecurityContextHolder} from '@jtm/jtm-services';
import {CustomerEnvironmentData} from '@jumio/portals.core';
import {InterceptorHttpParams} from 'public-shared/interceptors/http-interceptor/public-custom-http.interceptor';
import {BaseErrorCodes} from 'public-shared/models/error/base-error-codes';
import {ErrorDescriptor, ErrorItem} from 'public-shared/models/error/error-descriptor';
import {EnvironmentProvider} from 'public-shared/services/environment-provider';
import {Observable, throwError as _throw} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {HttpResponseCodes} from './constants';

@Injectable()
/**
 * Intercepts all HTTP requests and adds base URL, Authorization header and handles error responses.
 */
export class J4HttpInterceptor implements HttpInterceptor {
  public static readonly AUTHORIZATION_TOKEN_TYPE = 'Bearer';
  public static readonly HEADER_AUTHORIZATION_KEY = 'Authorization';
  public static readonly URLS_TO_SKIP = [
    'https://s3-us-west-2.amazonaws.com/s.cdpn.io/95368/world.json',
    '/api/external/j4/authentication/refreshToken'
  ];

  constructor(
    protected router: Router,
    protected securityContextHolder: JtmSecurityContextHolder,
    protected environmentProvider: EnvironmentProvider<CustomerEnvironmentData>
  ) {}

  protected static toErrorDescriptor(errorResponse: JumioErrorResponse): ErrorDescriptor {
    let errorDescriptor: ErrorDescriptor;

    if (errorResponse.status === 0) {
      errorDescriptor = new ErrorDescriptor('Server is down', BaseErrorCodes.SERVER_IS_DOWN.toString(), 0);
    } else if (errorResponse.status === HttpResponseCodes.NOT_FOUND) {
      //@ts-ignore
      errorDescriptor = new ErrorDescriptor('Not found', errorResponse.error?.errorCode, HttpResponseCodes.NOT_FOUND);
    } else {
      errorDescriptor = new ErrorDescriptor(
        //@ts-ignore
        errorResponse.error?.error,
        errorResponse.error?.errorCode,
        errorResponse.error?.status,
        errorResponse.error?.errors,
        errorResponse.error?.timestamp
      );
    }
    return errorDescriptor;
  }

  /**
   * The main method implementing the Interceptor interface.
   * Receives a HTTP request, and depending on the current environment, forwards it to either the mocked backend or the real backend.
   * After sending the events, catches error responses and converts them to ErrorDescriptor objects.
   * @param {HttpRequest<any>} req The current HTTP request that has been sent out from the client.
   * @param {HttpHandler} next The forwarding handler.
   * @returns {Observable<HttpEvent<any>>} An Observable which contains the response as an event stream.
   */
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = req.clone({
      url: this.isApiUrlAppended(req.url) ? req.url : this.prepareUrl(req.url, req.params),
      headers: !this.isSkipUrl(req.url) ? this.addAuthHeader(req.headers) : req.headers
    });

    const event$: Observable<HttpEvent<any>> = next.handle(req);

    return event$.pipe(
      catchError((errorResponse: HttpErrorResponse | ErrorDescriptor) => {
        // In case of 401 error, which is expired access token the request should be passed to JtmHttpInterceptor,
        // It  calls the refresh token mechanism, in any other cases the error message is formatted by toErrorDescriptor
        // function and processed by J4ErrorHandler
        if (errorResponse?.status !== HttpResponseCodes.UNAUTHORIZED) {
          // Make sure error is an ErrorDescriptor
          const e = this.parseToErrorDescriptor(errorResponse);

          // If it's an CloudFront error, it will be sent as a html error page
          // Map it to a proper ErrorDescriptor error

          const {error} = errorResponse;
          const errorMessage = typeof error === 'object' ? error.error : error;

          if (
            errorResponse?.status === HttpResponseCodes.FORBIDDEN &&
            errorMessage.includes('DOCTYPE HTML') &&
            errorMessage.includes('Request blocked')
          ) {
            e.errorCode = '403';
            e.error = 'Request blocked';
          }

          return _throw(e);
        }

        return event$;
      })
    );
  }

  /**
   * Depending on the parameters of the request, prepares the request's new URL.
   * @param {string} url The current URL contained by the request.
   * @param {HttpParams} params The params object of the request.
   * @returns {string} The new URL where the request will be sent.
   */
  protected prepareUrl(url: string, params: HttpParams): string {
    return params && (params as InterceptorHttpParams).skipApiUrl ? '' : this.environmentProvider.environment.apiUrl() + url;
  }

  /**
   * If available and not set yet, sets the Authorization header and returns a new headers object.
   * @param {HttpHeaders} headers The current headers of the request.
   * @returns {HttpHeaders} The new headers object that will be sent.
   */
  protected addAuthHeader(headers: HttpHeaders): HttpHeaders {
    if (this.getAuthToken()) {
      //@ts-ignore
      headers = headers.set(J4HttpInterceptor.HEADER_AUTHORIZATION_KEY, this.getAuthToken());
    }
    return headers;
  }

  /**
   * Creates the authorization token (formatted 'Bearer <authToken>').
   * @returns {string} The authorization token as a string.
   */
  protected getAuthToken(): string | null {
    let authToken: string | null = null;
    if (this.securityContextHolder.accessToken) {
      authToken = J4HttpInterceptor.AUTHORIZATION_TOKEN_TYPE + ' ' + this.securityContextHolder.accessToken;
    }
    return authToken;
  }

  /**
   *
   * @param url - request url
   * @private
   */
  private isApiUrlAppended(url: string): boolean {
    if (!url) {
      return false;
    }
    return /(http(s?)):\/\//i.test(url);
  }

  /**
   *
   * @param url - request url
   * @private
   */
  private isSkipUrl(url: string): boolean {
    return J4HttpInterceptor.URLS_TO_SKIP.reduce((acc, urlSkip) => {
      if (url.includes(urlSkip)) {
        acc = true;
      }
      return acc;
    }, false);
  }

  private parseToErrorDescriptor(errorResponse: HttpErrorResponse | ErrorDescriptor): ErrorDescriptor {
    return errorResponse instanceof ErrorDescriptor
      ? errorResponse
      : J4HttpInterceptor.toErrorDescriptor(errorResponse as JumioErrorResponse);
  }
}

/**
 * A helper class for extending the original HttpErrorResponse class,
 * which contains the errorCode, timestamp and status properties sent by the backend.
 */
export class JumioErrorResponse extends HttpErrorResponse {
  public override error:
    | {
        [key: string]: any;
        error: string;
        errorCode: string;
        timestamp: number;
        status: number;
        errors: Array<ErrorItem>;
      }
    | undefined;
}
