import {Directive, ElementRef, AfterViewInit, inject} from '@angular/core';

@Directive({
  selector: '[appFocusFirst]',
  standalone: true
})
export class FocusFirstDirective implements AfterViewInit {
  private el = inject(ElementRef);

  ngAfterViewInit(): void {
    setTimeout(() => {
      try {
        this.el.nativeElement.focus();
      } catch (error) {
        console.log('appFocusFirst error', error);
      }
    }, 0);
  }
}
