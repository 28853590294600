<mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="tree-check-list">
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
    <li class="mat-tree-node" data-testid="dashboard-edit-widget-child-tree-node">
      <mat-checkbox
        class="checklist-leaf-node"
        (change)="itemToggle($event.checked, node)"
        (click)="showSelectImag(node.id)"
        [checked]="node.selected"
        >{{ node.name }}</mat-checkbox
      >
    </li>
  </mat-tree-node>

  <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
    <li class="box-tree-node">
      <div class="mat-tree-node" data-testid="dashboard-edit-widget-parent-tree-node">
        <mat-checkbox
          [checked]="node.selected"
          [indeterminate]="node.indeterminate && !node.selected"
          (click)="showSelectImag(node.id)"
          (change)="itemToggle($event.checked, node)"
          >{{ node.name }}
        </mat-checkbox>
        <div class="select-toggle" mat-icon-button matTreeNodeToggle>
          <button [attr.aria-label]="'toggle ' + node.name" class="button-toggle">
            <mat-icon class="dynamic-icon" svgIcon="{{ treeControl.isExpanded(node) ? 'expand-more' : 'chevron-right' }}"> </mat-icon>
          </button>
        </div>
      </div>
      <ul [class.tree-check-list-invisible]="!treeControl.isExpanded(node)">
        <ng-container matTreeNodeOutlet></ng-container>
      </ul>
    </li>
  </mat-nested-tree-node>
</mat-tree>
