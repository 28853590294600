import {DOCUMENT} from '@angular/common';
import {Inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {JtmAuthService, JtmConfigurationService, JtmNavigationService, JtmSecurityContextHolder, QuickTourService} from '@jtm/jtm-services';
import {UserContext} from '@jtm/jtm-services/lib/services/auth/auth.dto';
import {UP_FEATURE_PATHS} from '@jumio/portals.up-shared';
import {Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {ErrorUrls, QuickTourUrls} from '../route-url-constants';
import {Auth0QueryParams} from './redirect.service.dto';

@Injectable({
  providedIn: 'root'
})
export class WelcomeRedirectService {
  /**
   * Login url
   */
  public loginUrl = '';

  /**
   * Access token
   */
  public accessToken = '';

  /**
   * Auth0 query param one time token
   */
  public code = '';

  /**
   * Auth0 query param selected tenant id
   */
  public tenantId = '';

  /**
   * Auth0 query param  error
   */
  public error = '';
  public errorDescription = '';

  constructor(
    private navigationService: JtmNavigationService,
    private contextHolder: JtmSecurityContextHolder,
    private auth: JtmAuthService,
    protected router: Router,
    private quickTourService: QuickTourService,
    private jtmConfigurationService: JtmConfigurationService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.accessToken = this.contextHolder.accessToken;

    this.setLoginUrl();
  }

  public setParams(params: Auth0QueryParams): void {
    this.code = params.code;
    this.tenantId = params.tenant;
    this.error = params.error;
    this.errorDescription = params.errorDescription;
  }

  public directsToPath(path: string): void {
    const internalModules = ['/dashboard', '/settings', '/verification-reports', '/verifications', '/reports'] as const;
    // because not evenry feature is lazy loaded we want to use the internal Angular router only if the target path is part of the Welcome app
    if (internalModules.find(module => path.includes(module))) {
      this.router.navigate([path]);
      return;
    }

    this.document.location.href = path;
  }

  /**
   * If Auth0 callback contains error query params, directs user to error page
   */
  public directsToErrorPage(): void {
    this.router.navigate([`${UP_FEATURE_PATHS.welcome}/${ErrorUrls.BASE}`], {
      queryParams: {error: this.error, errorDescription: this.errorDescription, tenantId: this.tenantId, code: this.code}
    });
  }

  public directsToQuickTourPage(): void {
    this.router.navigate([QuickTourUrls.QUICK_TOUR]);
  }

  /**
   * Before directs to Login page,  localstorage has to be deleted
   */
  public directsToLoginPage(params?: Record<string, string>): void {
    // Invalidate current token before login redirect
    this.contextHolder.invalidate();

    const url = !!params ? this.buildUrlWithParams(this.loginUrl, params) : this.loginUrl;

    this.document.location.replace(url);
  }

  public shouldShowQuickTour$(): Observable<boolean> {
    return this.quickTourService.shouldShowQuickTour();
  }

  public getFirstAvailableRouteOrQuickTour$(): Observable<string> {
    this.jtmConfigurationService.initialize();
    return this.shouldShowQuickTour$().pipe(
      switchMap(shouldShow => (shouldShow ? of(QuickTourUrls.QUICK_TOUR).pipe(map(url => `${url}/`)) : this.getFirstAvailableRoute$()))
    );
  }

  public getFirstAvailableRoute$(): Observable<string> {
    return this.navigationService.get1stAvailableRoute().pipe(map(url => url.toString()));
  }

  public getCurrentUser$(): Observable<UserContext> {
    return this.auth.getAccessToken({code: this.code, tenantId: this.tenantId});
  }

  public setLoginUrl(): void {
    this.loginUrl = this.auth.getLoginUrl();
  }

  public hasError(): boolean {
    return this.error?.length > 0;
  }

  public hasAccessToken(): boolean {
    return !!this.accessToken;
  }

  public hasTenantId(): boolean {
    return !!this.tenantId;
  }

  public hasCode(): boolean {
    return !!this.code;
  }

  private buildUrlWithParams(url: string, params: Record<string, string>): string {
    const httpParams = Object.keys(params).reduce((acc, key) => acc + `&${key}=${params[key]}`, '');

    return `${url}?${httpParams}`;
  }
}
