<app-dropdown [closeTrigger]="closeTrigger" [direction]="direction" [type]="type">
  <ng-content ngProjectAs=".dropdown-toggle"></ng-content>
  <div class="dropdown-content" id="dropdown-content">
    <div *ngIf="dropdownTitle" class="dropdown-item dropdown-title">{{ dropdownTitle }}</div>
    <div *ngIf="emptyIncluded" class="dropdown-item placeholder" (click)="selectValue(null)">{{ emptyLabel }}</div>
    <div class="dropdown-item" *ngFor="let value of values" [appIsEnabled]="$any(value).permission" (click)="selectValue(value)">
      {{ $any(value).label }}
    </div>
    <div
      *ngIf="hasDaterangePicker"
      class="dropdown-item"
      appDateRangeWrapper
      [initialDaterange]="$any(selectedCustomRange)"
      [currentDaterange]="$any(selectedCustomRange)"
      [options]="dateRangePickerConfig"
      (selected)="selectCustomRange($event)"
    >
      Custom date range
    </div>
  </div>
</app-dropdown>
