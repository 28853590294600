import {Component, ViewChild, inject} from '@angular/core';
import {MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {TreeChecklistComponent} from './tree-checklist/tree-checklist.component';

@Component({
  selector: 'j4-edit-widget',
  templateUrl: './edit-widget.component.html',
  styleUrls: ['./edit-widget.component.less']
})
export class EditWidgetComponent {
  @ViewChild(TreeChecklistComponent) public treeChecklist!: TreeChecklistComponent;
  private dialogRef = inject<MatDialogRef<EditWidgetComponent>>(MatDialogRef);
  public image: string = `welcome/assets/images/edit_widget/global_risk_analysis.png`;
  public activeButtonSave: boolean = false;

  public closePanel(): void {
    this.dialogRef.close(true);
  }

  public saveChanges(): void {
    this.treeChecklist.saveSelect();
    this.dialogRef.close(true);
  }

  public changeImage(image: string): void {
    this.activeButtonSave = true;
    this.image = `welcome/assets/images/edit_widget/${image}.png`;
  }
}
