import {NgModule} from '@angular/core';

import {FormsModule} from '@angular/forms';
import {MatNativeDateModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {RouterOutlet} from '@angular/router';
import {JtmComponentsModule} from '@jtm/jtm-components';
import {FocusFirstDirective, J4AlertComponent, ModalComponent, ModalConfirmComponent, PrettyJsonModule} from '@jumio/portals.core-ui';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {AlertModule} from 'ngx-bootstrap/alert';
import {ButtonsModule} from 'ngx-bootstrap/buttons';
import {CarouselModule} from 'ngx-bootstrap/carousel';
import {CollapseModule} from 'ngx-bootstrap/collapse';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {ModalModule} from 'ngx-bootstrap/modal';
import {TabsModule} from 'ngx-bootstrap/tabs';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {TypeaheadModule} from 'ngx-bootstrap/typeahead';
import {ColorPickerModule} from 'ngx-color-picker';
import {MomentModule} from 'ngx-moment';
import {NgProgress} from 'ngx-progressbar';
import {NgxTrimDirectiveModule} from 'ngx-trim-directive';
import {UiSwitchModule} from 'ngx-ui-switch';
import {SpinnerComponent} from 'public-shared/components/spinner/spinner.component';
import {PipesModule} from 'public-shared/pipes/pipes.module';
import {ToDashesIfEmptyPipe} from 'public-shared/pipes/to-dashes-if-empty.pipe';
import {ExternalScriptsService} from 'public-shared/services/external-scripts/external-scripts.service';
import {ForgotPasswordService} from 'public-shared/services/forgot-password/forgot-password.service';
import {LocalStorageService} from 'public-shared/services/local-storage/local-storage.service';
import {LoggerService} from 'public-shared/services/logging/logger.service';
import {NotificationService} from 'public-shared/services/notification/notification.service';
import {SharedPublicModule} from 'public-shared/shared-public.module';
import {DsrRequestsService} from 'shared/services/dsr-requests/dsr-requests.service';

import {NavButtonHelperService} from './components/abstract-nav-buttons/nav-button-helper.service';
import {AuthContainerComponent} from './components/auth-container/auth-container.component';
import {AutocompleteFieldComponent} from './components/autocomplete/autocomplete-field.component';
import {ClickOutsideDirective} from './components/click-outside-directive/click-outside.directive';
import {ClickToCopyComponent} from './components/click-to-copy/click-to-copy.component';
import {ClipboardCopyDirective} from './components/clipboard-copy/clipboard-copy.directive';
import {CollapsibleDirective} from './components/collapsible-directive/collapsible-directive';
import {CountdownTimerComponent} from './components/countdown-timer/countdown-timer.component';
import {DataTableModule} from './components/data-table/data-table.module';
import {DebounceClickDirective} from './components/debounce-directive/debounce-directive';
import {AppDropdownModule} from './components/dropdown/dropdown.module';
import {ErrorComponent} from './components/error/error.component';
import {AbstractEventsTabComponent} from './components/events-tab/events-tab.component';
import {FileUploaderComponent} from './components/file-uploader/file-uploader.component';
import {TextFilterComponent} from './components/filter/text-filter.component';
import {FormEnabledDirective} from './components/form-enabled-directive/form-enabled-directive';
import {GroupEnabledDirective} from './components/form-enabled-directive/group-enabled-directive';
import {FormGroupModule} from './components/form-group/form-group.module';
import {FormattedJsonBoxComponent} from './components/formatted-json-box/formatted-json-box.component';
import {ImageActionsModalComponent} from './components/image-actions-modal/image-actions-modal.component';
import {ImageSecureWrapperComponent} from './components/image-secure-wrapper/image-secure-wrapper.component';
import {ImageUploaderComponent} from './components/image-uploader/image-uploader.component';
import {InformativeDropdownComponent} from './components/informative-dropdown/informative-dropdown.component';
import {KyxAdvancedFilterComponent} from './components/kyx-advanced-filter/kyx-advanced-filter.component';
import {KyxCopyButtonComponent} from './components/kyx-copy-button/kyx-copy-button.component';
import {KyxDatepickerComponent} from './components/kyx-datepicker/kyx-datepicker.component';
import {KyxDropdownComponent} from './components/kyx-dropdown/kyx-dropdown.component';
import {KyxHeaderBarComponent} from './components/kyx-header-bar/kyx-header-bar.component';
import {KyxInputComponent} from './components/kyx-input/kyx-input.component';
import {KyxPanelComponent} from './components/kyx-panel/kyx-panel.component';
import {KyxStatusBadgeComponent} from './components/kyx-status-badge/kyx-status-badge.component';
import {KyxStatusIconComponent} from './components/kyx-status-icon/kyx-status-icon.component';
import {KyxTooltipComponent} from './components/kyx-tooltip/kyx-tooltip.component';
import {LoadingButtonComponent} from './components/loading-button/loading-button.component';
import {MsToCountdownTimerPipe} from './components/ms-to-countdown-timer-pipe/ms-to-time.pipe';
import {MultiSelectFieldComponent} from './components/multi-select-field/multi-select-field.component';
import {NaturalNumberInputComponent} from './components/natural-number-directive/natural-number-input.component';
import {NaturalNumberValidatorDirective} from './components/natural-number-directive/natural-number-validator.directive';
import {NavButtonsComponent} from './components/nav-buttons/nav-buttons.component';
import {NotificationComponent} from './components/notification/notification.component';
import {PageSpinnerComponent} from './components/page-spinner/page-spinner.component';
import {QrcodeComponent} from './components/qrcode/qrcode.component';
import {RadioGroupComponent} from './components/radio-group/radio-group.component';
import {ScanReferenceCopyButtonComponent} from './components/scan-reference-copy-button/scan-reference-copy-button.component';
import {IsEnabledDirective} from './components/security/is-enabled.directive';
import {IsHiddenDirective} from './components/security/is-hidden.directive';
import {IsRolesDisabled} from './components/security/is-roles-disabled.pipe';
import {IsVisibleDirective} from './components/security/is-visible.directive';
import {AnnotationToolComponent} from './components/smart-canvas/annotation-tool/annotation-tool.component';
import {SmartCanvasBaseComponent} from './components/smart-canvas/smart-canvas-base.component';
import {SmartCanvasComponent} from './components/smart-canvas/smart-canvas.component';
import {ThumbnailHoverToolComponent} from './components/smart-canvas/thumbnail-hover-tool/thumbnail-hover-tool.component';
import {StatusDotComponent} from './components/status-dot/status-dot.component';
import {PaginatorComponent} from './components/table/paginator/paginator.component';
import {TableDirective} from './components/table/table-directive/table.directive';
import {TabComponent} from './components/tabs/tab.component';
import {UnauthorizedComponent} from './components/unauthorized/unauthorized.component';
import {UnsavedChangesModalComponent} from './components/unsaved-changes-modal/unsaved-changes-modal.component';
import {WorkflowDatesComponent} from './components/workflow-dates/workflow-dates.component';

import {CustomerDashboardComponent} from './pages/dashboard/customer-dashboard.component';
import {FourStopPlatformRedirectComponent} from './pages/dashboard/platform-redirect-component/four-stop-platform-redirect.component';
import {JtmPlatformRedirectComponent} from './pages/dashboard/platform-redirect-component/jtm-platform-redirect.component';

import {AccountDetailsComponent} from './pages/my-profile/accounts/account-details/account-details.component';
import {KyxReportCreateComponent} from './pages/reports/create-report/platform/kyx/kyx-report.create.component';
import {V3ReportCreateComponent} from './pages/reports/create-report/platform/v3/v3-report.create.component';
import {ReportVerificationNameComponent} from './pages/reports/create-report/report-verification-name/report-verification-name.component';
import {J4V2ReportCreateComponent} from './pages/reports/create-report/v2/j4-v2-report.create.component';
import {V2ReportCreateComponent} from './pages/reports/create-report/v2/v2-report.create.component';
import {KYXReportComponent} from './pages/reports/list/kyx/kyx-report.component';
import {V2ReportComponent} from './pages/reports/list/v2/v2-report.component';
import {V3ReportComponent} from './pages/reports/list/v3/v3-report.component';
import {KyxReportVerificationFilterComponent} from './pages/reports/report-filter/platform-filter/kyx-report-platform-filter.component';
import {V3ReportVerificationFilterComponent} from './pages/reports/report-filter/platform-filter/v3-report-platform-filter.component';
import {V2ReportVerificationFilterComponent} from './pages/reports/report-filter/v2/report-verification-filter.component';
import {AcceptedIdsCountryComponent} from './pages/settings/accepted-ids/accepted-ids-country/accepted-ids-country.component';
import {AcceptedIdsRegionComponent} from './pages/settings/accepted-ids/accepted-ids-region/accepted-ids-region.component';
import {ApiTokensComponent} from './pages/settings/api-credentials/api-tokens/api-tokens.component';
import {ViewTokensComponent} from './pages/settings/api-credentials/api-tokens/tokens/view-tokens.component';
import {ApiUsersComponent} from './pages/settings/api-credentials/api-users/api-users.component';
import {DemoAppSetupComponent} from './pages/settings/api-credentials/demo-app-setup/demo-app-setup.component';
import {OAuthApiTokensComponent} from './pages/settings/api-credentials/oauth-tokens/oauth-tokens.component';
import {OfflineTokenComponent} from './pages/settings/api-credentials/offline-token/offline-token.component';
import {ApplicationSettingsGeneralComponent} from './pages/settings/application-settings/general/application-settings-general.component';
import {CountryIdsFilterComponent} from './pages/settings/country-ids-filter/country-ids-filter.component';
import {CustomizeClientGeneralComponent} from './pages/settings/customize-client/general/customize-client-general.component';
import {DataRetentionComponent} from './pages/settings/data-settings/data-retention/data-retention.component';
import {ExtractedFieldsComponent} from './pages/settings/data-settings/extracted-fields/extracted-fields.component';
import {ScreeningComponent} from './pages/settings/data-settings/screening/screening.component';
import {IdpManagementComponent} from './pages/settings/idp-management/idp-management.component';
import {UpdateUserPoolComponent} from './pages/settings/idp-management/update-pool/update-pool.component';
import {MultiDocumentCustomDetailComponent} from './pages/settings/multi-documents/custom/details/multi-document-custom-details.component';
import {MultiDocumentsCustomComponent} from './pages/settings/multi-documents/custom/multi-documents-custom.component';
import {MultiDocumentsSupportedComponent} from './pages/settings/multi-documents/supported/multi-documents-supported.component';
import {SettingsComponent} from './pages/settings/settings.component';
import {SupportedIdsComponent} from './pages/settings/supported-ids/supported-ids.component';

import {BooleanToTextPipe} from './pipes/boolean-to-text-pipe/boolean-to-text-pipe';
import {isPIIDataFieldPipe} from './pipes/is-pii-data-field/is-pii-data-field.pipe';
import {ShowAnnotationToolsPipe} from './pipes/show-annotation-tools.pipe';
import {SortByPipe} from './pipes/sort.pipe';
import {TrimTextMiddlePipe} from './pipes/trim-text-middle.pipe';
import {AccountsService} from './services/accounts/accounts.service';
import {AuthService} from './services/auth/auth.service';
import {CacheService} from './services/common/cache.service';
import {HeaderService} from './services/common/header-service';
import {IdleCheckService} from './services/common/idle-check-service';
import {CountryService} from './services/country/country.service';
import {CredentialsBmuiService} from './services/credentials/credentials-bmui.service';
import {CredentialsService} from './services/credentials/credentials.service';
import {OAuthCredentialsService} from './services/credentials/oauth-credentials.service';
import {DocumentsService} from './services/documents/documents.service';
import {DatasetsService} from './services/experiments/datasets.service';
import {ExperimentsService} from './services/experiments/experiments.service';
import {LegacyExperimentsService} from './services/experiments/legacy-experiments.service';
import {FileUploadCleanupService} from './services/file-upload-cleanup.service';
import {FraudService} from './services/fraud/fraud.service';
import {ImageService} from './services/image/image.service';
import {J4CustomerService} from './services/j4-customer/j4-customer.service';
import {J4GroupsService} from './services/j4-groups/j4-groups.service';
import {J4TenantsGenericKeysService} from './services/j4-tenants-generic-keys/j4-tenants-generic-keys.service';
import {J4TenantsService} from './services/j4-tenants/j4-tenants.service';
import {J4UsersService} from './services/j4-users/j4-users.service';
import {KYXTransactionsService} from './services/kyx-transactions/kyx-transactions.service';
import {MailTemplatesService} from './services/mail-templates/mail-templates.service';
import {MaskingService} from './services/masking/masking.service';
import {DialService} from './services/merchant/dial.service';
import {MerchantDataSettingsService} from './services/merchant/merchant-data-settings.service';
import {MerchantIntegrationSettingsService} from './services/merchant/merchant-integration-settings.service';
import {MerchantV2Service} from './services/merchant/merchant-v2.service';
import {MerchantService} from './services/merchant/merchant.service';
import {MyProfileService} from './services/my-profile/my-profile.service';
import {OrchestrationCustomerSpecificService} from './services/orchestration/orchestration-customer-specific.service';
import {OrchestrationGlobalService} from './services/orchestration/orchestration-global.service';
import {PdfService} from './services/pdf-builder/pdf.service';
import {RedirectService} from './services/redirect/redirect-service.service';
import {RiskManagementMerchantService} from './services/risk-management-merchant/risk-management-merchant.service';
import {SecurityContextHolder} from './services/security/security-context-holder';
import {CustomQueuesService} from './services/settings/custom-queues.service';
import {RiskManagementService} from './services/settings/risk-management.service';
import {SettingsService} from './services/settings/settings.service';
import {TryNowService} from './services/try-now/try-now.service';
import {UnsavedChangesService} from './services/unsaved-changes/unsaved-changes.service';
import {UserOperationsService} from './services/user/user.operations.service';
import {ScreeningService} from './services/verification/screening.service';
import {VerificationService} from './services/verification/verification.service';
import {WorkflowExecutionsHelperService} from './services/workflow-executions/workflow-execution-details.helper';
import {WorkflowExecutionsService} from './services/workflow-executions/workflow-executions.service';
import {WorkflowService} from './services/workflow/workflow.service';

@NgModule({
  declarations: [
    PaginatorComponent,
    AcceptedIdsCountryComponent,
    KyxCopyButtonComponent,
    KyxTooltipComponent,
    KyxStatusBadgeComponent,
    KyxStatusIconComponent,
    PageSpinnerComponent,
    NavButtonsComponent,
    MultiSelectFieldComponent,
    LoadingButtonComponent,
    KyxPanelComponent,
    KyxDatepickerComponent,
    KyxAdvancedFilterComponent,
    AbstractEventsTabComponent,
    AutocompleteFieldComponent,
    KyxInputComponent,
    KyxHeaderBarComponent,
    KYXReportComponent,
    ReportVerificationNameComponent,
    SupportedIdsComponent,
    SettingsComponent,
    UpdateUserPoolComponent,
    IdpManagementComponent,
    CustomizeClientGeneralComponent,
    CountryIdsFilterComponent,
    ViewTokensComponent,
    AcceptedIdsRegionComponent,
    JtmPlatformRedirectComponent,
    FourStopPlatformRedirectComponent,
    CustomerDashboardComponent,
    TableDirective,
    BooleanToTextPipe,
    ClickOutsideDirective,
    CollapsibleDirective,
    DebounceClickDirective,
    FormEnabledDirective,
    GroupEnabledDirective,
    IsHiddenDirective,
    IsVisibleDirective,
    UnauthorizedComponent,
    UnsavedChangesModalComponent,
    TabComponent,
    CountdownTimerComponent,
    MsToCountdownTimerPipe,
    TrimTextMiddlePipe,
    SortByPipe,
    InformativeDropdownComponent,
    ScanReferenceCopyButtonComponent,
    SmartCanvasBaseComponent,
    SmartCanvasComponent,
    AnnotationToolComponent,
    ThumbnailHoverToolComponent,
    WorkflowDatesComponent,
    FormattedJsonBoxComponent,
    ImageActionsModalComponent,
    ShowAnnotationToolsPipe,
    ClickToCopyComponent,
    isPIIDataFieldPipe,
    MultiDocumentsCustomComponent,
    StatusDotComponent,
    AuthContainerComponent,
    ClipboardCopyDirective,
    ErrorComponent,
    FileUploaderComponent,
    TextFilterComponent,
    KyxDropdownComponent,
    QrcodeComponent,
    RadioGroupComponent,
    AccountDetailsComponent,
    KyxReportCreateComponent,
    V3ReportCreateComponent,
    V2ReportCreateComponent,
    V2ReportComponent,
    V3ReportComponent,
    KyxReportVerificationFilterComponent,
    V3ReportVerificationFilterComponent,
    V2ReportVerificationFilterComponent,
    ApiTokensComponent,
    ApiUsersComponent,
    DemoAppSetupComponent,
    OAuthApiTokensComponent,
    OfflineTokenComponent,
    ApplicationSettingsGeneralComponent,
    DataRetentionComponent,
    ExtractedFieldsComponent,
    ScreeningComponent,
    MultiDocumentCustomDetailComponent,
    MultiDocumentsSupportedComponent,
    J4V2ReportCreateComponent
  ],
  imports: [
    PipesModule,
    NotificationComponent,
    AppDropdownModule,
    DataTableModule,
    NaturalNumberValidatorDirective,
    ModalComponent,
    ModalConfirmComponent,
    NaturalNumberInputComponent,
    FormsModule,
    ImageUploaderComponent,
    FormGroupModule,
    IsEnabledDirective,
    MatDatepickerModule,
    MatNativeDateModule,
    ToDashesIfEmptyPipe,
    IsRolesDisabled,
    RouterOutlet,
    JtmComponentsModule,
    SharedPublicModule,
    ColorPickerModule,
    ModalModule,
    MomentModule,
    TabsModule.forRoot(),
    UiSwitchModule.forRoot({
      color: '#97be0d'
    }),
    AlertModule.forRoot(),
    ButtonsModule.forRoot(),
    BsDropdownModule.forRoot(),
    CarouselModule.forRoot(),
    CollapseModule.forRoot(),
    TypeaheadModule.forRoot(),
    TooltipModule.forRoot(),
    NgxDatatableModule,
    NgxTrimDirectiveModule,
    ImageSecureWrapperComponent,
    PrettyJsonModule,
    SpinnerComponent,
    NaturalNumberValidatorDirective,
    FocusFirstDirective,
    J4AlertComponent
  ],
  providers: [
    VerificationService,
    ScreeningService,
    ImageService,
    UserOperationsService,
    MerchantService,
    MerchantV2Service,
    OrchestrationGlobalService,
    OrchestrationCustomerSpecificService,
    CustomQueuesService,
    RiskManagementMerchantService,
    MerchantDataSettingsService,
    MyProfileService,
    DocumentsService,
    SettingsService,
    CountryService,
    MailTemplatesService,
    ForgotPasswordService,
    OAuthCredentialsService,
    CredentialsService,
    CredentialsBmuiService,
    TryNowService,
    FraudService,
    HeaderService,
    IdleCheckService,
    LegacyExperimentsService,
    ExperimentsService,
    DatasetsService,
    NgProgress,
    WorkflowService,
    NotificationService,
    RiskManagementService,
    PdfService,
    DialService,
    SecurityContextHolder,
    AuthService,
    ForgotPasswordService,
    CacheService,
    ExternalScriptsService,
    LoggerService,
    RedirectService,
    LocalStorageService,
    RedirectService,
    WorkflowExecutionsService,
    AccountsService,
    WorkflowExecutionsHelperService,
    FileUploadCleanupService,
    KYXTransactionsService,
    NavButtonHelperService,
    KYXTransactionsService,
    MaskingService,
    MerchantIntegrationSettingsService,
    J4CustomerService,
    J4TenantsService,
    J4TenantsGenericKeysService,
    J4UsersService,
    J4GroupsService,
    DsrRequestsService,
    UnsavedChangesService
  ],
  exports: [
    PaginatorComponent,
    KyxCopyButtonComponent,
    KyxTooltipComponent,
    KyxStatusBadgeComponent,
    KyxStatusIconComponent,
    AutocompleteFieldComponent,
    KyxDatepickerComponent,
    KyxPanelComponent,
    LoadingButtonComponent,
    MultiSelectFieldComponent,
    NavButtonsComponent,
    AbstractEventsTabComponent,
    SupportedIdsComponent,
    SettingsComponent,
    KyxInputComponent,
    KyxHeaderBarComponent,
    KYXReportComponent,
    ReportVerificationNameComponent,
    UpdateUserPoolComponent,
    IdpManagementComponent,
    CustomizeClientGeneralComponent,
    CountryIdsFilterComponent,
    ViewTokensComponent,
    AcceptedIdsRegionComponent,
    JtmPlatformRedirectComponent,
    FourStopPlatformRedirectComponent,
    CustomerDashboardComponent,
    SharedPublicModule,
    BooleanToTextPipe,
    DebounceClickDirective,
    ClickOutsideDirective,
    FormEnabledDirective,
    GroupEnabledDirective,
    IsVisibleDirective,
    IsHiddenDirective,
    UnauthorizedComponent,
    NaturalNumberInputComponent,
    CollapsibleDirective,
    ModalComponent,
    ModalConfirmComponent,
    UiSwitchModule,
    UnsavedChangesModalComponent,
    NotificationComponent,
    TabComponent,
    TrimTextMiddlePipe,
    CountdownTimerComponent,
    MsToCountdownTimerPipe,
    SortByPipe,
    InformativeDropdownComponent,
    ScanReferenceCopyButtonComponent,
    SmartCanvasBaseComponent,
    SmartCanvasComponent,
    AnnotationToolComponent,
    ThumbnailHoverToolComponent,
    WorkflowDatesComponent,
    FormattedJsonBoxComponent,
    ImageActionsModalComponent,
    ShowAnnotationToolsPipe,
    ClickToCopyComponent,
    isPIIDataFieldPipe,
    TableDirective,
    PageSpinnerComponent,
    KyxAdvancedFilterComponent,
    StatusDotComponent,
    AuthContainerComponent,
    ClipboardCopyDirective,
    ErrorComponent,
    FileUploaderComponent,
    TextFilterComponent,
    KyxDropdownComponent,
    QrcodeComponent,
    RadioGroupComponent,
    AccountDetailsComponent,
    KyxReportCreateComponent,
    V3ReportCreateComponent,
    V2ReportCreateComponent,
    V2ReportComponent,
    V3ReportComponent,
    KyxReportVerificationFilterComponent,
    V3ReportVerificationFilterComponent,
    V2ReportVerificationFilterComponent,
    ApiTokensComponent,
    ApiUsersComponent,
    DemoAppSetupComponent,
    OAuthApiTokensComponent,
    OfflineTokenComponent,
    ApplicationSettingsGeneralComponent,
    DataRetentionComponent,
    ExtractedFieldsComponent,
    ScreeningComponent,
    MultiDocumentCustomDetailComponent,
    MultiDocumentsSupportedComponent,
    J4V2ReportCreateComponent
  ]
})
export class SharedModule {}
