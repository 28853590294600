import {Component, ErrorHandler, OnInit, ViewChild, ViewContainerRef, inject} from '@angular/core';
import {UntilDestroy} from '@jumio/portals.core';
import {ServiceUnavailableService} from '@jumio/portals.core-ui';
import {J4ErrorHandler} from 'core/services';
import {Subscription} from 'rxjs';
import {ErrorDescriptor} from './models/error-descriptor';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit {
  @ViewChild('serviceUnavailable', {read: ViewContainerRef, static: true}) private serviceUnavailablePlaceholder:
    | ViewContainerRef
    | undefined;
  private readonly serviceUnavailableService = inject(ServiceUnavailableService);
  private readonly errorHandler = inject(ErrorHandler);

  public readonly subscription = new Subscription();

  public ngOnInit(): void {
    this.serviceUnavailableService.register(this.serviceUnavailablePlaceholder);
    this.subscription.add(
      (this.errorHandler as J4ErrorHandler).unavailableServiceSubject?.subscribe({
        next: (_error: ErrorDescriptor) => {
          this.serviceUnavailableService.show();
        },
        error: _error => {}
      })
    );
  }
}
