import {AfterViewInit, Component, Input, Optional, ViewChild} from '@angular/core';
import {ControlValueAccessor, FormControl, NgModel, NgModelGroup} from '@angular/forms';
import {AbstractFormGroupComponent} from 'public-shared/components/form-group/abstract/abstract-form-group';

/**
 * The base component for holding inputs inside form groups.
 */
@Component({
  template: ''
})
export abstract class AbstractFormGroupInputComponent extends AbstractFormGroupComponent implements ControlValueAccessor, AfterViewInit {
  /**
   * The type of the input component to be used.
   * By default it's a text input.
   * @type {string}
   */
  @Input() public type: string = 'text';

  /**
   * The placeholder that will show up in the input.
   * By default it's empty.
   * @type {string}
   */
  @Input() public placeholder: string = '';

  /**
   * Sets the maximum value that can be entered in numeric inputs.
   */
  @Input() public max: number | undefined;

  /**
   * Sets the minimum value that can be entered in numeric inputs.
   */
  @Input() public min: number | undefined;

  /**
   * Sets the step value that can be entered in numeric inputs.
   */
  @Input() public step: number | undefined;

  /**
   * This value defines whether the input will appear as an input-group or not.
   */
  @Input() public group: boolean = false;

  /**
   * Sets a regex pattern validation for the input.
   * The form validator will return a 'pattern' error key if the value is invalid
   */
  @Input() public pattern: string | undefined;

  @Input() public maxlength: number | undefined;

  /**
   * By default we are trimming input fields on onBlur event by third party component ngx-trim-directive
   * This is causing issues in some cases so we want to be able to disallow functionality and use our
   * custom trimming.
   */
  @Input() public customTrim: boolean = false;

  /**
   * The Angular Model of this current input.
   */
  @ViewChild(NgModel) public model: NgModel | undefined;

  /**
   * Holds the value of the current input.
   */
  public value: string | number | undefined;

  private readonly TEXT_TYPE = 'text';
  private readonly URL_TYPE = 'url';
  private readonly NUMBER_TYPE = 'number';
  private readonly EMAIL_TYPE = 'email';
  private readonly PASSWORD_TYPE = 'password';
  private readonly NATURAL_NUMBER_TYPE = 'natural-number';

  // New type for custom validation logic - creating this type to avoid backward
  // compatibility issues with already existing inputs
  // TODO: Maybe in the future we can deprecate the older solutions if they will become obsolete
  private readonly CUSTOM_TYPE = 'custom-validation';

  /**
   * Optionally can be part of a model group.
   */
  constructor(@Optional() private modelGroup: NgModelGroup) {
    super();
  }

  /**
   * The function which emits touch events to the parent component.
   */
  public propagateTouch: any = () => {};

  /**
   * The function which emits model changes to the containing component.
   */
  public propagateChange: any = () => {};

  /**
   * If the component is part of a model group, add a new form control to it based on the component.
   */
  ngAfterViewInit(): void {
    Promise.resolve(null).then(() => {
      //@ts-ignore
      if (this.model && this.modelGroup && this.modelGroup.control && !this.modelGroup.control.contains(this.name)) {
        //@ts-ignore
        this.modelGroup.control.addControl(this.name, new FormControl());
      }
    });
  }

  /**
   * Indicates whether the current input is "simple", which means it can be declared using a HTML <input> tag.
   * @returns {boolean}
   */
  public get isSimpleInput(): boolean | string {
    return (
      this.type &&
      (this.type === this.TEXT_TYPE ||
        this.type === this.URL_TYPE ||
        this.type === this.NUMBER_TYPE ||
        this.type === this.PASSWORD_TYPE ||
        this.type === this.EMAIL_TYPE ||
        this.type === this.CUSTOM_TYPE)
    );
  }

  /**
   * Indicates whether the current input is an e-mail input.
   * @returns {boolean}
   */
  public get isEmailInput(): boolean | string {
    return this.type && this.type === this.EMAIL_TYPE;
  }

  /**
   * Indicates whether the current input is a natural number input.
   * Currently available only on AP and TI
   * @returns {boolean}
   */
  public get isNaturalNumberInput(): boolean | string {
    return this.type && this.type === this.NATURAL_NUMBER_TYPE;
  }

  /**
   * Indicates whether the current input is a custom validated input.
   * @returns {boolean}
   */
  public get isCustomValidationInput(): boolean {
    return !!(this.type && this.type === this.CUSTOM_TYPE);
  }

  public get trim(): any {
    return this.customTrim === true ? false : 'blur';
  }

  /**
   * Handles changes of the input. Emits a new change containing the original event,
   * and propagates the new model value to the parent component.
   * @param event The original Event object coming from input's "change" event.
   */
  public onChange(event: any): void {
    this.valueChange.emit(event);
    this.propagateChange(this.value);
  }

  /**
   * Receives a value from the parent component, then writes it to the current model.
   * Since this component may include another custom control (e.g. NaturalNumberComponent),
   * we need to explicitly call its "writeValue" method.
   * @param {string | number} newValue The new value of the model coming from the parent component.
   */
  public writeValue(newValue: string | number): void {
    this.value = newValue;
    if (this.model && this.model.valueAccessor) {
      this.model.valueAccessor.writeValue(this.value);
    }
  }

  /**
   * OnBlur event we are trimming values in the field. It shouldn't be done onChange event,
   * as we want to allow users input spaces in text, but not in the beginning/end of the string
   */
  public onBlur(): void {
    if (this.customTrim === true && this.value) {
      this.value = this.value.toString().trim();
      this.propagateChange(this.value);
    }
  }

  /**
   * Registers the "propagateChange" function as the function which will be used
   * to emit new model values to the parent component.
   * @param {(_: any) => void} fn
   */
  public registerOnChange(fn: (_: any) => void): void {
    this.propagateChange = fn;
  }

  /**
   * Empty implementation of the ControlValueAccessor interface's method.
   * @param fn
   */
  public registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  /**
   * Sets the disabled property the current input.
   * @param {boolean} disabled the new value of the disabled property
   */
  public setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }
}
