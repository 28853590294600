import {Component, Input} from '@angular/core';
import {SpinnerTypes} from './spinner.constants';
import {RepeatPipe} from 'public-shared/pipes/repeat.pipe';
import {NgClass, NgForOf} from '@angular/common';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.less'],
  standalone: true,
  imports: [RepeatPipe, NgForOf, NgClass]
})
export class SpinnerComponent {
  @Input() public type: SpinnerTypes = SpinnerTypes.LIGHT;

  public getRectClass(index: number): string {
    return `rect${index + 1} ${this.type}`;
  }
}
