import {HttpClientModule} from '@angular/common/http';
import {APP_INITIALIZER, ModuleWithProviders, NgModule, Optional, SkipSelf, inject} from '@angular/core';

import {WINDOW} from '@jumio/portals.core';
import {FF_BASE_API_URL, FeatureFlags} from './constants/feature-flags.constants';
import {FeatureFlagService} from './services/feature-flag.service';
import {FeatureFlagConfigService} from './services/http-services/feature-flag-config.service';

@NgModule({
  declarations: [],
  imports: [HttpClientModule],
  providers: [
    {
      provide: FF_BASE_API_URL,
      useFactory: (win: Window): string => win.location.origin,
      deps: [WINDOW]
    },
    FeatureFlagConfigService,
    FeatureFlagService
  ],
  exports: []
})
export class JumioPortalFeatureFlagModule {
  constructor(@Optional() @SkipSelf() parentModule: JumioPortalFeatureFlagModule) {
    if (parentModule) {
      throw new Error('JumioPortalFeatureFlagModule has already been imported.');
    }
  }

  public static forRoot(): ModuleWithProviders<JumioPortalFeatureFlagModule> {
    return {
      ngModule: JumioPortalFeatureFlagModule,
      providers: [
        {
          provide: APP_INITIALIZER,
          useFactory: (): (() => Promise<FeatureFlags>) => {
            const serviceInstance = inject(FeatureFlagService);

            // Load the feature flag file
            return () => serviceInstance.loadFeatureFlags();
          },
          deps: [FeatureFlagService],
          multi: true
        }
      ]
    };
  }
}
