export class TableConstants {
  public static readonly DEFAULT_LIMIT_SERVER = 10;
  public static readonly DEFAULT_LIMIT_TI_SERVER = 20;
  public static readonly DEFAULT_LIMIT_CLIENT = 25;
  public static readonly DEFAULT_SORTABLE = false;
  public static readonly DEFAULT_DRAGGABLE = false;
  public static readonly DEFAULT_RESIZEABLE = false;
  public static readonly DEFAULT_HEADER_HEIGHT = 30;
  public static readonly EMPTY_MESSAGE = '';

  // TODO: Once the BE is ready, change it to a BE parameter
  public static readonly MAX_SERVER_RESPONSE_DATA = 1000;
}
