import {Component, ErrorHandler, HostBinding, Renderer2, inject} from '@angular/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {Title} from '@angular/platform-browser';
import {ActivationEnd, Router} from '@angular/router';
import {ButtonSizes, ButtonVariants} from '@jtm/jtm-components';
import {Permission, UntilDestroy, getCurrentTenantName} from '@jumio/portals.core';
import {J4ErrorHandler, RedirectService} from '@jumio/portals.up-shared';
import {ErrorDescriptor} from 'public-shared/models/error/error-descriptor';
import {Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';
import {EditWidgetComponent} from '../edit-widget/edit-widget.component';

@UntilDestroy()
@Component({
  selector: 'j4-dashboard-root',
  templateUrl: './dashboard-root.component.html',
  styleUrls: ['./dashboard-root.component.less', './custom-components.less']
})
export class DashboardRootComponent {
  @HostBinding('@.disabled')
  /**
   * If true, all animations will be disabled in the application.
   */
  public serviceError: ErrorDescriptor | null | undefined;
  protected breadcrumbsItems = [
    {label: 'Dashboard', url: '/'},
    {label: getCurrentTenantName(), url: ''}
  ];

  private readonly dialog = inject(MatDialog);
  public readonly subscription = new Subscription();
  public readonly buttonSizes = ButtonSizes;
  public readonly buttonVariants = ButtonVariants;
  public status = {status: 'IP'};
  public stateLabel = 'Onboarding';
  public topBarConfig = {
    showBreadcrumbs: true,
    showCaseStatus: false,
    showUserGroupList: true,
    showLeftMiniButton: false,
    showRightMiniButton: true,
    showStateLabel: true,
    showCurrentAssignee: false,
    showDueDate: false,
    showRuleLabelList: true,
    showPrimaryButton: true,
    showSecondaryButton: true
  };
  public readonly Permission = Permission;
  public readonly TITLE_POSTFIX = ' - Jumio Portal';
  public breadcrumbsIcon = `<svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M5.49995 12.25V8.50004H8.49995V12.25C8.49995 12.6625 8.83745 13 9.24995 13H11.5C11.9125 13 12.25 12.6625 12.25 12.25V7.00004H13.525C13.87 7.00004 14.035 6.57254 13.7725 6.34754L7.50245 0.700039C7.21745 0.445039 6.78245 0.445039 6.49745 0.700039L0.227452 6.34754C-0.0275485 6.57254 0.129951 7.00004 0.474951 7.00004H1.74995V12.25C1.74995 12.6625 2.08745 13 2.49995 13H4.74995C5.16245 13 5.49995 12.6625 5.49995 12.25Z" fill="#8993A6"/>
  </svg>
  `;

  constructor(
    private redirectService: RedirectService,
    private router: Router,
    title: Title,
    private errorHandler: ErrorHandler,
    private renderer: Renderer2
  ) {
    //Check if user is loggedIn and redirects to login page if is not
    if (this.redirectService.shouldRedirectToLogin()) {
      this.redirectService.redirectToLoginPage();
    } else {
      this.subscription.add(
        this.router.events
          .pipe(filter(event => event instanceof ActivationEnd && event?.snapshot?.children.length === 0))
          .subscribe(event => {
            const titleParam = (event as ActivationEnd).snapshot.data['title'] ?? '';
            title.setTitle(`${titleParam}${this.TITLE_POSTFIX}`);

            try {
              const loader = this.renderer.selectRootElement('#pre-load-spinner');
              this.renderer.setStyle(loader, 'display', 'none');
            } catch (e) {
              // Do nothing
            }
          })
      );
    }
  }

  public openEditWidget(): void {
    const dialogRef = this.dialog.open(EditWidgetComponent, {
      disableClose: true,
      panelClass: 'custom-modal-box',
      backdropClass: 'custom-modal-box-background'
    });

    // TODO: investigate why this line is necessary, if not needed please remove
    this.subscription.add(dialogRef.afterClosed().subscribe((_confirmed: boolean) => {}));
  }
}
