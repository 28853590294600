export enum WidgetsType {
  ItemListMonitor = 'j4-item-list-monitor',
  SingleLineCategoryMonitor = 'j4-singleline-category-monitor',
  AdvancedItemListMonitor = 'j4-advanced-item-list-monitor',
  StackedBarCategoryMonitor = 'j4-stackedbar-category-monitor',
  RadialBarCategoryMonitor = 'j4-radialbar-category-monitor',
  DonutCategoryMonitor = 'j4-donut-category-monitor',
  GeoMapListMonitor = 'j4-geo-map-list-monitor',
  StackedLineCategoryMonitor = 'j4-stackedline-category-monitor',
  SingleLineDecisionMonitor = 'j4-singleline-decision-monitor',
  PerformanceMetricsMonitor = 'j4-performance-metrics-monitor'
}

export enum WidgetsCategory {
  AML = 'aml',
  KYC = 'kyc'
}

export enum WidgetsIds {
  TRANSACTIONS_USAGE = 0,
  KYC_TRANSACTIONS = 1,
  NEW_CUSTOMERS = 2,
  CHANNEL_SOURCE = 3,
  TRANSACTIONS_PER_DOCUMENT_TYPE = 4,
  GLOBAL_RISK_ANALYSIS = 5,
  TRANSACTIONS_PER_PRODUCT = 6,
  CAPABILITY_USAGE = 7,
  DEVICE_RISK_DECISION = 8,
  ERYC_RISK_DECISION = 9,
  EMAIL_RISK_DECISION = 10,
  PHONE_RISK_DECISION = 11,
  YOUR_CASES = 12,
  SAR_FILLING_RECOMMENDED = 13,
  SAR_FILED = 14,
  OLDEST_OPEN_CASES = 15,
  UPCOMING_CASE_DEADLINES = 16,
  PERFORMANCE_METRICS = 17
}

export interface IYearMonthDay {
  year: number;
  month: number;
  day: number;
}
