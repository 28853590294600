<div [class.macOS-moz-padding]="isMacOS">
  <div class="body-row" *ngFor="let item of items" (click)="onItemClick(item)" [class.has-navigation]="hasNavigation">
    <div
      class="body-cell"
      *ngFor="let col of columns"
      [class.has-navigation]="hasNavigation"
      [class.current-sort]="isCurrentSort(col)"
      [style.width]="col.colWidth ? col.colWidth + 'px' : col.calculatedPercentage + '%'"
    >
      <ng-container *ngTemplateOutlet="$any(col).templateRef; context: {$implicit: item}"></ng-container>
    </div>
  </div>
</div>
