import {Component, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {RedirectService} from '../../services/redirect/redirect.service';
import {UntilDestroy} from '@jumio/portals.core';

@UntilDestroy()
@Component({
  selector: 'j4-error-redirect',
  templateUrl: './error-redirect.component.html',
  standalone: true,
  providers: [RedirectService]
})
export class ErrorRedirectComponent implements OnInit {
  public readonly subscription = new Subscription();

  constructor(protected redirectService: RedirectService) {}

  ngOnInit(): void {
    this.subscription.add(
      this.redirectService.getFirstAvailablePage$().subscribe(url => {
        this.redirectService.redirectToPath(url);
      })
    );
  }
}
