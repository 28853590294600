import {Injectable, inject} from '@angular/core';
import {ErrorDescriptor} from 'public-shared/models/error/error-descriptor';
import {Subject} from 'rxjs';
import {SecurityContextHolder} from 'shared/services/security/security-context-holder';

export interface StateMessage {
  message?: string;
}

export interface SecurityData {
  password?: string;
  passCode?: string | null;
}

@Injectable()
export class PasswordOrConfirmModalService {
  private securityContextHolder = inject(SecurityContextHolder);
  public state = new Subject<StateMessage | null>();
  public data = new Subject<SecurityData>();
  public error = new Subject<ErrorDescriptor>();

  public openWithMessage(message: string): void {
    this.state.next({message});
  }

  public open(): void {
    return !!this.securityContextHolder.refreshToken
      ? this.openWithMessage('Are you sure you want to continue?')
      : this.openWithMessage('Please verify your password before saving changes.');
  }

  public close(): void {
    this.state.next(null);
  }

  public showError(error: ErrorDescriptor): void {
    this.error.next(error);
  }
}
