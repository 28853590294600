/* eslint-disable rxjs/finnish */
import {Injectable, inject} from '@angular/core';
import {Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {
  IPayload,
  IMonitor,
  INewCustomers,
  IChannelSource,
  ITransactionUsage,
  IProductTransactions,
  IDocumentTypeTransactions,
  ICapabilityUsage,
  ICases,
  ISars,
  IDocumentTypeTransactionDecisions,
  IPerformanceMetrics
} from './monitor.interface';
import {JtmConfigurationService, JtmUserUiConfigurationService} from '@jtm/jtm-services';
import {newCustomersResponseMock} from './mocks/newCustomers.mock';
import {channelSourceResponseMock} from './mocks/channelSource.mock';
import {kycDecisionTransactionsResponseMock} from './mocks/kycDecisionTransactions.mock';
import {kycRiskTransactionsResponseMock} from './mocks/kycRiskTransactions.mock';
import {productTransactionsResponseMock} from './mocks/productTransactions.mock';
import {documentTypeTransactionsResponseMock} from './mocks/documentTypeTransactions.mock';
import {sarsFilingRecommendedResponseMock} from './mocks/sarsFilingRecommended.mock';
import {sarsFiledResponseMock} from './mocks/sarsFiled.mock';
import {yourCasesResponseMock} from './mocks/yourCases.mock';
import {transactionUsageResponseMock} from './mocks/transactionUsage.mock';
import {capabilityUsageResponseMock} from './mocks/capabilityUsage.mock';
import {deviceRiskDecisionResponseMock} from './mocks/deviceRiskDecision.mock';
import {ekycRiskDecisionResponseMock} from './mocks/ekycRiskDecision.mock';
import {emailRiskDecisionResponseMock} from './mocks/emailRiskDecision.mock';
import {phoneRiskDecisionResponseMock} from './mocks/phoneRiskDecision.mock';
import {oldestOpenCasesMock} from './mocks/oldestOpenCases.mock';
import {upcomingCaseDeadlinesMock} from './mocks/upcomingCaseDeadlines.mock';
import {performanceMetricsResponseMock} from './mocks/performanceMetrics.mock';
import {WidgetsIds} from '../../pages/dashboard/dashboard.component.dto';
import {EnvironmentProvider} from 'public-shared/services/environment-provider';
import {CustomerEnvironmentData} from '@jumio/portals.core';

@Injectable({
  providedIn: 'root'
})
export class MonitorService {
  private environmentProvider: EnvironmentProvider<CustomerEnvironmentData> = inject(EnvironmentProvider);
  private apiUrl = `${this.environmentProvider.environment.apiUrl()}v1/monitors`;
  private monitors = {
    newCustomers: {id: 'new_customers', isMocked: false},
    channelSource: {id: 'channel_source', isMocked: false},
    kycDecisionTypeTransactions: {
      id: 'kyc_decision_type_transactions',
      isMocked: false
    },
    kycRiskTypeTransactions: {
      id: 'kyc_risk_type_transactions',
      isMocked: false
    },
    productTransactions: {id: 'product_transactions', isMocked: false},
    documentTypeTransactions: {
      id: 'document_type_transactions',
      isMocked: false
    },
    capabilityUsage: {id: 'capability_usage', isMocked: false},
    globalRiskAnalysis: {id: 'global_risk', isMocked: false},
    transactionUsage: {id: 'transaction_usage', isMocked: false},
    deviceRiskDecision: {id: 'device_risk_decision', isMocked: true},
    ekycRiskDecision: {id: 'ekyc_risk_decision', isMocked: true},
    emailRiskDecision: {id: 'email_risk_decision', isMocked: true},
    phoneRiskDecision: {id: 'phone_risk_decision', isMocked: true},
    globakRiskAnalysis: {id: 'global_risk', isMocked: true},
    performanceMetrics: {id: 'performance_metrics', isMocked: true},
    // jtm widgets
    sarsFiled: {id: 'sars_filed', isMocked: true},
    sarsFilingRecommended: {id: 'sars_filing_recommended', isMocked: true},
    yourCases: {id: 'your_cases', isMocked: true},
    oldestOpenCases: {id: 'oldest_open_cases', isMocked: true},
    upcomingCaseDeadlines: {id: 'upcoming_case_deadlines', isMocked: true}
  };

  public defaultDashboard = [
    'KYC_TRANSACTIONS',
    'NEW_CUSTOMERS',
    'CHANNEL_SOURCE',
    'TRANSACTIONS_PER_DOCUMENT_TYPE',
    'GLOBAL_RISK_ANALYSIS',
    'TRANSACTIONS_PER_PRODUCT',
    'CAPABILITY_USAGE'
    // 'YOUR_CASES',
    // 'SAR_FILED',
    // 'SAR_FILLING_RECOMMENDED',
    // 'OLDEST_OPEN_CASES',
    // 'UPCOMING_CASE_DEADLINES',
    // 'PERFORMANCE_METRICS',
  ];

  constructor(
    private jtmConfigurationService: JtmConfigurationService,
    private jtmUserUiConfigurationService: JtmUserUiConfigurationService,
    private httpClient: HttpClient
  ) {
    this.jtmConfigurationService.initialize();
  }

  public getNewCustomers$(payload: IPayload): Observable<INewCustomers> {
    return this.getMonitorData$(this.monitors.newCustomers, payload);
  }

  public getChannelSource$(payload: IPayload): Observable<IChannelSource> {
    return this.getMonitorData$(this.monitors.channelSource, payload);
  }

  public getTransactionUsage$(payload: IPayload): Observable<ITransactionUsage> {
    return this.getMonitorData$(this.monitors.transactionUsage, payload);
  }

  public getKycDecisionTransactions$<IKycTransactions>(payload: IPayload): Observable<IKycTransactions> {
    return this.getMonitorData$(this.monitors.kycDecisionTypeTransactions, payload);
  }

  public getKycRiskTypeTransactions$<IKycTransactions>(payload: IPayload): Observable<IKycTransactions> {
    return this.getMonitorData$(this.monitors.kycRiskTypeTransactions, payload);
  }

  public getProductTransactions$(payload: IPayload): Observable<IProductTransactions> {
    return this.getMonitorData$(this.monitors.productTransactions, payload);
  }

  public getDocumentTypeTransactions$(payload: IPayload): Observable<IDocumentTypeTransactions> {
    return this.getMonitorData$(this.monitors.documentTypeTransactions, payload);
  }

  public getYourCases$(payload: IPayload): Observable<ICases> {
    return this.getMonitorData$(this.monitors.yourCases, payload);
  }

  public getSarsFiled$(payload: IPayload): Observable<ISars> {
    return this.getMonitorData$(this.monitors.sarsFiled, payload);
  }

  public getSarsFilingRecommended$(payload: IPayload): Observable<ISars> {
    return this.getMonitorData$(this.monitors.sarsFilingRecommended, payload);
  }

  public getCapabilityUsage$(payload: IPayload): Observable<ICapabilityUsage> {
    return this.getMonitorData$(this.monitors.capabilityUsage, payload);
  }
  public getDeviceRiskDecision$(payload: IPayload): Observable<IDocumentTypeTransactionDecisions> {
    return this.getMonitorData$(this.monitors.deviceRiskDecision, payload);
  }

  public getEkycRiskDecision$(payload: IPayload): Observable<IDocumentTypeTransactionDecisions> {
    return this.getMonitorData$(this.monitors.ekycRiskDecision, payload);
  }

  public getEmailRiskDecision$(payload: IPayload): Observable<IDocumentTypeTransactionDecisions> {
    return this.getMonitorData$(this.monitors.emailRiskDecision, payload);
  }

  public getPhoneRiskDecision$(payload: IPayload): Observable<IDocumentTypeTransactionDecisions> {
    return this.getMonitorData$(this.monitors.phoneRiskDecision, payload);
  }

  public getPerformanceMetrics$(payload: IPayload): Observable<IPerformanceMetrics> {
    return this.getMonitorData$(this.monitors.performanceMetrics, payload);
  }

  // TODO: investigate the proper type for this Observable
  public getGlobalRiskAnalysis$(payload: IPayload): Observable<any> {
    return this.getMonitorData$(this.monitors.globalRiskAnalysis, payload);
  }

  public getOldestOpenCases$(payload: IPayload): Observable<ISars> {
    return this.getMonitorData$(this.monitors.oldestOpenCases, payload);
  }

  public getUpcomingCaseDeadlines$(payload: IPayload): Observable<ISars> {
    return this.getMonitorData$(this.monitors.upcomingCaseDeadlines, payload);
  }

  public saveMonitorsByIds(monitorsIds: number[]): Observable<any> {
    const monitorsNames = monitorsIds.map(monitor => WidgetsIds[monitor]);
    return this.saveMonitorsByNames(monitorsNames);
  }

  public saveMonitorsByNames(monitorsIds: string[]): Observable<void> {
    return this.jtmUserUiConfigurationService.saveUserPreference({
      key: 'j4.dashboard.order',
      value: JSON.stringify(monitorsIds)
    });
  }

  public getUserDashboard(): Observable<any> {
    return this.jtmUserUiConfigurationService.getUserPreference('j4.dashboard.order');
  }

  public initializeConfigurationService(): void {
    this.jtmConfigurationService.initialize();
  }

  private getMockedMonitorData$(id: string): Observable<unknown> {
    switch (id) {
      case 'capability_usage':
        return of(capabilityUsageResponseMock);
      case 'new_customers':
        return of(newCustomersResponseMock);
      case 'channel_source':
        return of(channelSourceResponseMock);
      case 'transaction_usage':
        return of(transactionUsageResponseMock);
      case 'kyc_decision_type_transactions':
        return of(kycDecisionTransactionsResponseMock);
      case 'kyc_risk_type_transactions':
        return of(kycRiskTransactionsResponseMock);
      case 'product_transactions':
        return of(productTransactionsResponseMock);
      case 'document_type_transactions':
        return of(documentTypeTransactionsResponseMock);
      case 'sars_filed':
        return of(sarsFiledResponseMock);
      case 'sars_filing_recommended':
        return of(sarsFilingRecommendedResponseMock);
      case 'your_cases':
        return of(yourCasesResponseMock);
      case 'device_risk_decision':
        return of(deviceRiskDecisionResponseMock);
      case 'ekyc_risk_decision':
        return of(ekycRiskDecisionResponseMock);
      case 'email_risk_decision':
        return of(emailRiskDecisionResponseMock);
      case 'phone_risk_decision':
        return of(phoneRiskDecisionResponseMock);
      case 'oldest_open_cases':
        return of(oldestOpenCasesMock);
      case 'upcoming_case_deadlines':
        return of(upcomingCaseDeadlinesMock);
      case 'performance_metrics':
        return of(performanceMetricsResponseMock);
      default:
        return of(null);
    }
  }

  private getMonitorData$<T>(monitor: IMonitor, payload: IPayload): Observable<T> {
    if (monitor.isMocked) {
      return this.getMockedMonitorData$(monitor.id) as Observable<T>;
    }
    return this.httpClient.post<T>(`${this.apiUrl}/${monitor.id}`, payload);
  }
}
