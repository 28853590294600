import {Injectable} from '@angular/core';
import {combineLatest, Observable, Subject, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';

import {
  ICapabilityUsage,
  ICases,
  IChannelSource,
  IDashboardMonitor,
  IDocumentTypeTransactionDecisions,
  IDocumentTypeTransactions,
  IKycTransactions,
  IPerformanceMetrics,
  INewCustomers,
  IPayload,
  IProductTransactions,
  ISars,
  ITransactionUsage,
  IWidgetItem
} from './monitor.interface';
import {MonitorService} from './monitor.service';
import {UntilDestroy} from '@jumio/portals.core';
import {WidgetsCategory, WidgetsIds, WidgetsType} from '../../pages/dashboard/dashboard.component.dto';
import {createCalculatedWidth} from './monitor.util';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class MonitorFacade {
  public readonly subscription = new Subscription();
  public items: IWidgetItem[] = [];
  public items$ = new Subject<IWidgetItem[]>();
  public widgetsType = 'aml';

  constructor(private monitorService: MonitorService) {}

  public setItems(items: IWidgetItem[]): void {
    if (this.widgetsType) {
      this.items$.next(items.filter(item => item.widgetCategory === this.widgetsType));
    }
  }

  public swapItems(item1: IWidgetItem, item2: IWidgetItem): void {
    if (this.widgetsType) {
      const index1 = this.items.findIndex(item => item.id === item1.id);
      const index2 = this.items.findIndex(item => item.id === item2.id);

      [this.items[index1], this.items[index2]] = [this.items[index2], this.items[index1]];

      const filteredItems = this.items.filter(item => item.widgetCategory === this.widgetsType);

      this.items$.next(filteredItems);
    }
  }

  // eslint-disable-next-line rxjs/finnish
  public saveWidgetsByIds(widgetIds: number[]): Observable<any> {
    return this.monitorService.saveMonitorsByIds(widgetIds);
  }

  // eslint-disable-next-line rxjs/finnish
  public saveWidgetsByNames(widgetIds: string[]): Observable<any> {
    return this.monitorService.saveMonitorsByNames(widgetIds);
  }

  public filterWidgetsType(widgetsType: string): void {
    this.widgetsType = widgetsType;
    this.setItems(this.items);
  }

  public loadItems(items: IWidgetItem[]): void {
    this.items = items;
  }

  public getWidgets(payload: IPayload): void {
    // clear the data
    this.items.forEach(item => (item.data.metrics = null));

    this.getNewCustomers(payload);
    this.getChannelSource(payload);
    this.getKycTransactions(payload);
    this.getTransactionUsage(payload);
    this.getProductTransactions(payload);
    this.getDocumentTypeTransactions(payload);
    this.getCapabilityUsage(payload);
    this.getYourCases(payload);
    this.getSarsFiled(payload);
    this.getSarsFilingRecommended(payload);
    this.getDeviceRiskDecision(payload);
    this.getEkycRiskDecision(payload);
    this.getEmailRiskDecision(payload);
    this.getPhoneRiskDecision(payload);
    this.getGlobalRiskAnalysis(payload);
    this.getOldestOpenCases(payload);
    this.getUpcomingCaseDeadlines(payload);
    this.getPerformanceMetrics(payload);
  }

  public getWidget(payload: IPayload, widgetId: string): void {
    switch (widgetId) {
      case 'NEW_CUSTOMERS':
        this.getNewCustomers(payload);
        break;
      case 'CHANNEL_SOURCE':
        this.getChannelSource(payload);
        break;
      case 'KYC_TRANSACTIONS':
        this.getKycTransactions(payload);
        break;
      case 'TRANSACTIONS_USAGE':
        this.getTransactionUsage(payload);
        break;
      case 'TRANSACTIONS_PER_PRODUCT':
        this.getProductTransactions(payload);
        break;
      case 'TRANSACTIONS_PER_DOCUMENT_TYPE':
        this.getDocumentTypeTransactions(payload);
        break;
      case 'CAPABILITY_USAGE':
        this.getCapabilityUsage(payload);
        break;
      case 'YOUR_CASES':
        this.getYourCases(payload);
        break;
      case 'SAR_FILED':
        this.getSarsFiled(payload);
        break;
      case 'SAR_FILLING_RECOMMENDED':
        this.getSarsFilingRecommended(payload);
        break;
      case 'DEVICE_RISK_DECISION':
        this.getDeviceRiskDecision(payload);
        break;
      case 'ERYC_RISK_DECISION':
        this.getEkycRiskDecision(payload);
        break;
      case 'EMAIL_RISK_DECISION':
        this.getEmailRiskDecision(payload);
        break;
      case 'PHONE_RISK_DECISION':
        this.getPhoneRiskDecision(payload);
        break;
      case 'GLOBAL_RISK_ANALYSIS':
        this.getGlobalRiskAnalysis(payload);
        break;
      case 'OLDEST_OPEN_CASES':
        this.getOldestOpenCases(payload);
        break;
      case 'UPCOMING_CASE_DEADLINES':
        this.getUpcomingCaseDeadlines(payload);
        break;
      case 'PERFORMANCE_METRICS':
        this.getPerformanceMetrics(payload);
        break;
      default:
        console.error('getWidgets: unknown widget');
    }
  }

  public getUserDashboard$(): Observable<string[]> {
    return this.monitorService
      .getUserDashboard()
      .pipe(map(dashboard => (dashboard ? JSON.parse(dashboard.value) : this.monitorService.defaultDashboard)));
  }

  public getDashboardWidgetItems(widgetIds: string[], isDemo: boolean): IWidgetItem[] {
    return widgetIds.map(widgetId => this.getWidgetItem(widgetId, isDemo)).map(createCalculatedWidth);
  }

  public getWidgetItem(widgetId: string, isDemo: boolean): IWidgetItem {
    switch (widgetId) {
      case 'NEW_CUSTOMERS':
        return {
          cols: 2,
          rows: 5,
          pos: 0,
          widgetCategory: WidgetsCategory.KYC,
          id: WidgetsIds.NEW_CUSTOMERS,
          type: WidgetsType.SingleLineCategoryMonitor,
          data: {
            demo: isDemo,
            title: 'New Customers',
            totalLabel: 'Total # of New Customers',
            avgLabel: 'Average per day',
            tooltipTotalLabel: 'Customers',
            lineColor: ['#26AC64', '#F2C94C', '#EB5757'],
            metrics: null,
            labels: [
              {
                key: 'customer',
                value: 'Customer'
              }
            ]
          }
        };
      case 'CHANNEL_SOURCE':
        return {
          cols: 1,
          rows: 5,
          pos: 0,
          widgetCategory: WidgetsCategory.KYC,
          id: WidgetsIds.CHANNEL_SOURCE,
          type: WidgetsType.DonutCategoryMonitor,
          data: {
            demo: isDemo,
            title: 'Channel Source',
            showSubInfo: false,
            metrics: null
          }
        };
      case 'KYC_TRANSACTIONS':
        return {
          cols: 2,
          rows: 5,
          pos: 0,
          widgetCategory: WidgetsCategory.KYC,
          id: WidgetsIds.KYC_TRANSACTIONS,
          type: WidgetsType.StackedBarCategoryMonitor,
          data: {
            demo: isDemo,
            title: 'KYC Transactions',
            totalLabel: 'Total',
            avgLabel: 'Avg per day',
            tooltipTotalLabel: 'Total Transactions', // subtitle for Tooltip on grouped/total labels
            metrics: null,
            labels: [
              {
                key: 'risk',
                value: 'Risk Score'
              },
              {
                key: 'decision',
                value: 'Status'
              }
            ]
          }
        };
      case 'TRANSACTIONS_USAGE':
        return {
          cols: 1,
          rows: 5,
          pos: 0,
          widgetCategory: WidgetsCategory.KYC,
          id: WidgetsIds.TRANSACTIONS_USAGE,
          type: WidgetsType.RadialBarCategoryMonitor,
          data: {
            demo: isDemo,
            widgetTitle: 'Transactions Usage',
            metrics: null,
            context: null
          }
        };
      case 'TRANSACTIONS_PER_PRODUCT':
        return {
          cols: 2,
          rows: 5,
          pos: 0,
          widgetCategory: WidgetsCategory.KYC,
          id: WidgetsIds.TRANSACTIONS_PER_PRODUCT,
          type: WidgetsType.StackedLineCategoryMonitor,
          data: {
            demo: isDemo,
            title: 'Transactions per Product',
            totalLabel: 'Total',
            avgLabel: 'Avg per day',
            tooltipTotalLabel: 'Total Transactions',
            metrics: null,
            context: null,
            labels: []
          }
        };
      case 'TRANSACTIONS_PER_DOCUMENT_TYPE':
        return {
          cols: 2,
          rows: 5,
          pos: 0,
          widgetCategory: WidgetsCategory.KYC,
          id: WidgetsIds.TRANSACTIONS_PER_DOCUMENT_TYPE,
          type: WidgetsType.StackedLineCategoryMonitor,
          data: {
            demo: isDemo,
            title: 'Transactions per Document Type',
            totalLabel: 'Total',
            avgLabel: 'Avg per day',
            tooltipTotalLabel: 'Total Transactions',
            metrics: null,
            context: null,
            labels: []
          }
        };
      case 'CAPABILITY_USAGE':
        return {
          cols: 2,
          rows: 5,
          pos: 0,
          widgetCategory: WidgetsCategory.KYC,
          id: WidgetsIds.CAPABILITY_USAGE,
          type: WidgetsType.StackedLineCategoryMonitor,
          data: {
            demo: isDemo,
            title: 'Capability Usage',
            totalLabel: null,
            avgLabel: null,
            tooltipTotalLabel: 'Total Transactions',
            metrics: null,
            context: null,
            labels: []
          }
        };
      case 'YOUR_CASES':
        return {
          cols: 1,
          rows: 5,
          pos: 0,
          widgetCategory: WidgetsCategory.AML,
          id: WidgetsIds.YOUR_CASES,
          type: WidgetsType.ItemListMonitor,
          data: {
            demo: isDemo,
            title: 'Your Cases',
            metrics: null,
            context: null
          }
        };
      case 'SAR_FILED':
        return {
          cols: 1,
          rows: 5,
          pos: 0,
          widgetCategory: WidgetsCategory.AML,
          id: WidgetsIds.SAR_FILED,
          type: WidgetsType.AdvancedItemListMonitor,
          data: {
            demo: isDemo,
            title: 'SARs Filed',
            subTitle: 'Total # SARs Filed',
            status: 'Filed',
            type: 'compact',
            metrics: null,
            context: null
          }
        };
      case 'SAR_FILLING_RECOMMENDED':
        return {
          cols: 1,
          rows: 5,
          y: 0,
          x: 0,
          widgetCategory: WidgetsCategory.AML,
          id: WidgetsIds.SAR_FILLING_RECOMMENDED,
          type: WidgetsType.AdvancedItemListMonitor,
          data: {
            demo: isDemo,
            title: 'SAR Filing Recommended',
            subTitle: 'Total # of Recommendations',
            status: 'Recommended',
            metrics: null,
            context: null
          }
        };
      case 'GLOBAL_RISK_ANALYSIS':
        return {
          cols: 2,
          rows: 5,
          pos: 0,
          widgetCategory: WidgetsCategory.KYC,
          id: WidgetsIds.GLOBAL_RISK_ANALYSIS,
          type: WidgetsType.GeoMapListMonitor,
          data: {
            demo: isDemo,
            title: 'Global Risk Analysis'
          }
        };
      case 'OLDEST_OPEN_CASES':
        return {
          cols: 1,
          rows: 5,
          pos: 0,
          widgetCategory: WidgetsCategory.AML,
          id: WidgetsIds.OLDEST_OPEN_CASES,
          type: WidgetsType.AdvancedItemListMonitor,
          data: {
            demo: isDemo,
            title: 'Oldest Open Cases',
            metrics: null,
            context: null
          }
        };
      case 'UPCOMING_CASE_DEADLINES':
        return {
          cols: 1,
          rows: 5,
          pos: 0,
          widgetCategory: WidgetsCategory.AML,
          id: WidgetsIds.UPCOMING_CASE_DEADLINES,
          type: WidgetsType.AdvancedItemListMonitor,
          data: {
            demo: isDemo,
            title: 'Upcoming Case Deadlines',
            metrics: null,
            context: null
          }
        };
      case 'PERFORMANCE_METRICS':
        return {
          cols: 2,
          rows: 5,
          x: 2,
          y: 0,
          widgetCategory: WidgetsCategory.AML,
          id: WidgetsIds.PERFORMANCE_METRICS,
          type: WidgetsType.PerformanceMetricsMonitor,
          data: {
            demo: isDemo,
            title: 'My Performance',
            tooltipTotalLabel: 'Total Cases Closed', // subtitle for Tooltip on grouped/total labels
            tooltipAvgTimeLabel: 'Avg time per case (min)',
            metrics: null,
            labels: [
              {
                key: 'cases_closed',
                value: 'Cases Closed'
              },
              {
                key: 'average_closed',
                value: 'Average closed per day'
              },
              {
                key: 'average_time',
                value: 'Average time per case'
              }
            ]
          }
        };
      case 'DEVICE_RISK_DECISION':
      case 'ERYC_RISK_DECISION':
      case 'EMAIL_RISK_DECISION':
      case 'PHONE_RISK_DECISION':
      default:
        console.error('Widget is not implemented in Monitor facade', widgetId);
        return {} as IWidgetItem;
    }
  }

  private updateWidget(widget: UpdateWidgetArgs, id: number): void {
    const widgetIndex = this.items.findIndex(item => item.id === id);

    if (widgetIndex === -1) {
      return;
    }

    const newWidget: IWidgetItem = {
      ...this.items[widgetIndex],
      data: {
        ...this.items[widgetIndex].data,
        metrics: widget.data,
        context: widget.context
      }
    };

    this.items.splice(widgetIndex, 1, newWidget);
    this.setItems(this.items);
  }

  private getNewCustomers(payload: IPayload): void {
    this.subscription.add(
      this.monitorService.getNewCustomers$(payload).subscribe((newCustomers: INewCustomers) => {
        this.updateWidget(newCustomers, WidgetsIds.NEW_CUSTOMERS);
      })
    );
  }

  private getChannelSource(payload: IPayload): void {
    this.subscription.add(
      this.monitorService.getChannelSource$(payload).subscribe((channelSource: IChannelSource) => {
        this.updateWidget(channelSource, WidgetsIds.CHANNEL_SOURCE);
      })
    );
  }

  private getKycTransactions(payload: IPayload): void {
    this.subscription.add(
      combineLatest([
        this.monitorService.getKycRiskTypeTransactions$(payload) as Observable<IKycTransactions>,
        this.monitorService.getKycDecisionTransactions$(payload) as Observable<IKycTransactions>
      ]).subscribe((typeTransactions: IKycTransactions[]) => {
        const kycTransactions: IKycTransactions = {
          ...typeTransactions[0],
          data: {
            ...typeTransactions[0].data,
            categories: [...typeTransactions[0].data.categories, ...typeTransactions[1].data.categories]
          }
        };
        this.updateWidget(kycTransactions, WidgetsIds.KYC_TRANSACTIONS);
      })
    );
  }

  private getTransactionUsage(payload: IPayload): void {
    this.subscription.add(
      this.monitorService.getTransactionUsage$(payload).subscribe((transactionUsage: ITransactionUsage) => {
        this.updateWidget(transactionUsage, WidgetsIds.TRANSACTIONS_USAGE);
      })
    );
  }

  private getProductTransactions(payload: IPayload): void {
    this.subscription.add(
      this.monitorService.getProductTransactions$(payload).subscribe((productTransactions: IProductTransactions) => {
        this.updateWidget(productTransactions, WidgetsIds.TRANSACTIONS_PER_PRODUCT);
      })
    );
  }

  private getDocumentTypeTransactions(payload: IPayload): void {
    this.subscription.add(
      this.monitorService.getDocumentTypeTransactions$(payload).subscribe((documentTypeTransactions: IDocumentTypeTransactions) => {
        this.updateWidget(documentTypeTransactions, WidgetsIds.TRANSACTIONS_PER_DOCUMENT_TYPE);
      })
    );
  }

  private getCapabilityUsage(payload: IPayload): void {
    this.subscription.add(
      this.monitorService.getCapabilityUsage$(payload).subscribe((capabilityUsage: ICapabilityUsage) => {
        this.updateWidget(capabilityUsage, WidgetsIds.CAPABILITY_USAGE);
      })
    );
  }

  private getYourCases(payload: IPayload): void {
    this.subscription.add(
      this.monitorService.getYourCases$(payload).subscribe((yourCases: ICases) => {
        this.updateWidget(yourCases, WidgetsIds.YOUR_CASES);
      })
    );
  }

  private getSarsFiled(payload: IPayload): void {
    this.subscription.add(
      this.monitorService.getSarsFiled$(payload).subscribe((sarsFiled: ISars) => {
        this.updateWidget(sarsFiled, WidgetsIds.SAR_FILED);
      })
    );
  }

  private getSarsFilingRecommended(payload: IPayload): void {
    this.subscription.add(
      this.monitorService.getSarsFilingRecommended$(payload).subscribe((sarsFilingRecommended: ISars) => {
        this.updateWidget(sarsFilingRecommended, WidgetsIds.SAR_FILLING_RECOMMENDED);
      })
    );
  }

  private getDeviceRiskDecision(payload: IPayload): void {
    this.subscription.add(
      this.monitorService.getDeviceRiskDecision$(payload).subscribe((deviceRiskDecision: IDocumentTypeTransactionDecisions) => {
        this.updateWidget(deviceRiskDecision, WidgetsIds.DEVICE_RISK_DECISION);
      })
    );
  }

  private getEkycRiskDecision(payload: IPayload): void {
    this.subscription.add(
      this.monitorService.getEkycRiskDecision$(payload).subscribe((ekycRiskDecision: IDocumentTypeTransactionDecisions) => {
        this.updateWidget(ekycRiskDecision, WidgetsIds.ERYC_RISK_DECISION);
      })
    );
  }

  private getEmailRiskDecision(payload: IPayload): void {
    this.subscription.add(
      this.monitorService.getEmailRiskDecision$(payload).subscribe((emailRiskDecision: IDocumentTypeTransactionDecisions) => {
        this.updateWidget(emailRiskDecision, WidgetsIds.EMAIL_RISK_DECISION);
      })
    );
  }

  private getPhoneRiskDecision(payload: IPayload): void {
    this.subscription.add(
      this.monitorService.getPhoneRiskDecision$(payload).subscribe((phoneRiskDecision: IDocumentTypeTransactionDecisions) => {
        this.updateWidget(phoneRiskDecision, WidgetsIds.PHONE_RISK_DECISION);
      })
    );
  }

  private getGlobalRiskAnalysis(payload: IPayload): void {
    this.subscription.add(
      this.monitorService.getGlobalRiskAnalysis$(payload).subscribe((globalRiskAnalysis: IDashboardMonitor) => {
        this.updateWidget(globalRiskAnalysis, WidgetsIds.GLOBAL_RISK_ANALYSIS);
      })
    );
  }

  private getOldestOpenCases(payload: IPayload): void {
    this.subscription.add(
      this.monitorService.getOldestOpenCases$(payload).subscribe((oldestOpenCases: ISars) => {
        this.updateWidget(oldestOpenCases, WidgetsIds.OLDEST_OPEN_CASES);
      })
    );
  }

  private getUpcomingCaseDeadlines(payload: IPayload): void {
    this.subscription.add(
      this.monitorService.getUpcomingCaseDeadlines$(payload).subscribe((upcomingCaseDeadlines: ISars) => {
        this.updateWidget(upcomingCaseDeadlines, WidgetsIds.UPCOMING_CASE_DEADLINES);
      })
    );
  }

  private getPerformanceMetrics(payload: IPayload): void {
    this.subscription.add(
      this.monitorService.getPerformanceMetrics$(payload).subscribe((performanceMetrics: IPerformanceMetrics) => {
        this.updateWidget(performanceMetrics, WidgetsIds.PERFORMANCE_METRICS);
      })
    );
  }
}
type UpdateWidgetArgs =
  | INewCustomers
  | IChannelSource
  | ITransactionUsage
  | ICases
  | ISars
  | IDocumentTypeTransactionDecisions
  | IDashboardMonitor
  | IPerformanceMetrics;
