import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Helpers} from 'shared/components/helpers/helpers';

import {LoadingButtonStyles} from './loading-button.constants';
import {SpinnerTypes} from 'public-shared/components/spinner/spinner.constants';

@Component({
  selector: 'app-loading-button',
  templateUrl: './loading-button.component.html',
  styleUrls: ['./loading-button.component.less']
})
export class LoadingButtonComponent {
  @Input() public type = 'button';
  @Input() public isPending: boolean = false;
  @Input() public disabled = false;
  @Input() public style: LoadingButtonStyles = LoadingButtonStyles.PRIMARY;
  @Output() public onClick = new EventEmitter<void>();

  public LoadingButtonStyles = LoadingButtonStyles;

  public get buttonClasses(): string {
    const classes = 'button loading-button';

    switch (this.style) {
      case LoadingButtonStyles.PRIMARY:
        return classes + ' button-primary';
      case LoadingButtonStyles.DEFAULT:
        return classes + ' button-default';
      default:
        return classes;
    }
  }

  public get spinnerType(): string {
    return this.style === LoadingButtonStyles.PRIMARY ? SpinnerTypes.LIGHT : SpinnerTypes.DARK;
  }

  public get isIEBrowser(): boolean {
    return Helpers.isIEBrowser();
  }
}
