import {Component} from '@angular/core';
import {ViewComponent} from '../view.component';

@Component({
  selector: 'app-card-view',
  templateUrl: './card-view.component.html',
  styleUrls: ['./card-view.component.less']
})
export class CardViewComponent extends ViewComponent {
  public SUMMARY_KEY = 'summary';

  public getSummaryClass(item: any): string {
    return item && item[this.SUMMARY_KEY] ? item[this.SUMMARY_KEY].toLowerCase() : '';
  }
}
