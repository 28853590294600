import {NgFor} from '@angular/common';
import {ChangeDetectionStrategy, Component, forwardRef, Input, OnInit} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormArray,
  FormBuilder,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  ValidationErrors,
  Validator,
  Validators
} from '@angular/forms';
import {ButtonSizes, ButtonVariants, JtmComponentsModule} from '@jtm/jtm-components';
import {UntilDestroy} from '@jumio/portals.core';
import {Subscription} from 'rxjs';
import {FormGroupModule} from 'shared/components/form-group/form-group.module';

@UntilDestroy()
@Component({
  selector: 'j4-form-group-array-input',
  templateUrl: './form-group-array-input.component.html',
  styleUrls: ['./form-group-array-input.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [JtmComponentsModule, ReactiveFormsModule, FormGroupModule, NgFor],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormGroupArrayInputComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FormGroupArrayInputComponent),
      multi: true
    }
  ]
})
export class FormGroupArrayInputComponent implements ControlValueAccessor, OnInit, Validator {
  /**
   * If set to true, the FormGroup will be disabled.
   */
  @Input() public disabled: boolean = false;

  /**
   * If set to true, the FormGroup will be required to fill.
   * The form validator will return a 'required' error key if the input is empty
   */
  @Input() public required: boolean = false;

  @Input() public addButtonLabel = 'Add item';

  public readonly formGroup = this.fb.group({
    array: this.fb.array([])
  });
  public readonly formControls = (this.formGroup.get('array') as FormArray).controls;
  public readonly buttonSizes = ButtonSizes;
  public readonly buttonVariants = ButtonVariants;

  public readonly errorMessages = {required: 'This field is required'};
  public readonly subscription = new Subscription();
  public value: string[] | undefined;

  constructor(private fb: FormBuilder) {}

  public propagateChange: any = () => {};
  public propagateTouch: any = () => {};
  public onValidationChange: any = () => {};

  public ngOnInit(): void {
    this.subscription.add((this.formGroup.get('array') as FormArray).valueChanges.subscribe((v: string[]) => this.updateValue(v)));
  }

  public validate(_control: AbstractControl): ValidationErrors {
    const childIsValid = this.formControls.reduce((acc, v) => acc && v.valid, true);

    return !childIsValid ? {required: 'Please fill all the fields'} : {};
  }

  public registerOnValidatorChange?(fn: () => void): void {
    this.onValidationChange = fn;
  }

  public writeValue(value: string[]): void {
    this.value = value;

    this.setFormArray(value);
    this.onValidationChange();
  }

  public updateValue(value: string[]): void {
    this.value = value;

    this.onValidationChange();
    this.propagateChange(this.value);
  }

  public registerOnChange(fn: (_: any) => void): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: (_: any) => void): void {
    this.propagateTouch = fn;
  }

  public setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  public addItem(): void {
    const arrayControl = this.formGroup.get('array') as FormArray;
    if (arrayControl instanceof FormArray) {
      arrayControl.push(new FormControl(null, Validators.required));
    }
  }

  public onRemoveButtonClick(rowIndex: number): void {
    const arrayControl = this.formGroup.get('array');
    if (arrayControl instanceof FormArray) {
      arrayControl.removeAt(rowIndex);
    }
  }

  public onlyOneRowLeft(): boolean {
    const formArray = this.formGroup.get('array') as FormArray;
    return formArray?.length <= 1;
  }

  public removeButtonTooltip(): string {
    return this.onlyOneRowLeft() ? 'At least one new item must be provided' : 'Remove item';
  }

  private setFormArray(value: string[] | null): void {
    const arrayControl = this.formGroup.get('array');

    if (value && arrayControl instanceof FormArray) {
      arrayControl.clear();
      value.forEach(v => arrayControl.push(new FormControl(v, Validators.required)));
    }
  }
}
