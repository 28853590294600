import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateChildFn, CanActivateFn, Router} from '@angular/router';
import {JtmSecurityContextHolder} from '@jtm/jtm-services';
import {Observable} from 'rxjs';

/**
 * This guard prevents loading pages depending on authorization of the user.
 */
export const j4AuthorizationGuard: CanActivateFn = (route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean => {
  const router = inject(Router);
  const securityContextHolder = inject(JtmSecurityContextHolder);

  const roles = (route.data['roles'] as string[]) ?? [];
  return isActivable(securityContextHolder, router, roles);
};

/**
 * Checks whether the component and all of its children can be loaded.
 */
export const j4AuthorizationGuardActivateChild: CanActivateChildFn = (
  childRoute: ActivatedRouteSnapshot
): Observable<boolean> | Promise<boolean> | boolean => {
  const router = inject(Router);
  const securityContextHolder = inject(JtmSecurityContextHolder);

  const roles = (childRoute.data['roles'] as Array<string>) || null;
  return isActivable(securityContextHolder, router, roles);
};

/**
 * Checks whether the currently logged used has the required permissions to view the page.
 * @returns {boolean} True, if the currently logged in user has the permissions to view the page.
 */
const isAuthorized = (securityContextHolder: JtmSecurityContextHolder, roles: string[]): boolean =>
  roles.length > 0 && roles.some(role => securityContextHolder.hasPermission(role));

/**
 * Checks whether the user is logged in and has the required permissions to view the given page.
 * @returns {boolean} True, if the given route is reachable by the current user.
 */
const isActivable = (securityContextHolder: JtmSecurityContextHolder, router: Router, roles: string[]): boolean => {
  if (!securityContextHolder.isLoggedIn) {
    router.navigate(['/403']).then(() => false);
    return false;
  }

  if (roles?.length && !isAuthorized(securityContextHolder, roles)) {
    router.navigate(['/403']).then(() => false);
    return false;
  }

  return true;
};
