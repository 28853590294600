export const performanceMetricsResponseMock = {
  data: {
    dimensions: [1658794890000, 1658881290000, 1658967690000, 1.659012e12, 1.659112e12, 1.659212e12, 1.659312e12],
    categories: [
      {
        name: 'cases_closed',
        total: {
          value: 28.0,
          percentage: 0.2
        },
        values: [35.0, 38.0, 35.0, 52.0, 35.0, 37.0, 32.0],
        averages: [],
        series: [
          {
            name: 'In Review',
            total: 3,
            percentage: 0.45,
            values: [20.0, 21.0, 20.0, 30.0, 20.0, 22.0, 19.0]
          },
          {
            name: 'Flagged',
            total: 3,
            percentage: 0.05,
            values: [10.0, 11.0, 10.0, 15.0, 10.0, 12.0, 9.0]
          },
          {
            name: 'Reversed',
            total: 2,
            percentage: 0.01,
            values: [5.0, 6.0, 5.0, 7.0, 5.0, 4.0, 3.0]
          }
        ]
      },
      {
        name: 'average_closed',
        total: {
          value: 24.0,
          percentage: 0.2
        },
        values: [9.0],
        averages: [],
        series: [
          {
            name: 'Pass',
            total: 0,
            values: [10, 41, 19, 19, 37, 18, 32]
          }
        ]
      },
      {
        name: 'average_time',
        total: {
          value: 12.0,
          percentage: -0.2
        },
        values: [111.0, 98.0, 108.0, 86.0, 105.0, 98.0, 92.0],
        averages: [12.0, 12.0, 12.0, 12.0, 12.0, 12.0, 12.0],
        series: [
          {
            name: 'Structuring',
            total: 8,
            percentage: null,
            values: [25.0, 27.0, 21.0, 24.0, 26.0, 23.0, 20.0]
          },
          {
            name: 'Payment Threshold',
            total: 10,
            percentage: null,
            values: [49.0, 39.0, 38.0, 30.0, 43.0, 42.0, 40.0]
          },
          {
            name: 'Type',
            total: 12,
            percentage: 0.0,
            values: [22.0, 20.0, 32.0, 17.0, 22.0, 17.0, 15.0]
          },
          {
            name: 'Other',
            total: 12,
            percentage: null,
            values: [15.0, 12.0, 17.0, 15.0, 14.0, 16.0, 17.0]
          }
        ]
      }
    ],
    total: {
      value: 0,
      percentage: 0
    },
    average: {
      value: 0,
      percentage: 0
    }
  },
  context: {
    aggregatorFormat: 'day',
    monitorDataType: 'com.jumio.dashboardeventsaggregator.domain.CategoryMonitor',
    dimensionDataType: 'java.lang.Long',
    seriesDataType: 'java.lang.Double',
    startTime: 1661385600000,
    endTime: 1661731200000,
    historyStartTime: 1629849600000,
    historyEndTime: 1630195200000,
    durationInaggregatorFormat: 4,
    processedStartTime: 1663640878564,
    processedEndTime: 1663640880372,
    processedTime: 1808
  }
};
