import {DOCUMENT} from '@angular/common';
import {Inject, Injectable} from '@angular/core';
import {JtmAuthService, JtmNavigationService, JtmSecurityContextHolder} from '@jtm/jtm-services';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable()
export class RedirectService {
  constructor(
    private contextHolder: JtmSecurityContextHolder,
    private authService: JtmAuthService,
    private navigationService: JtmNavigationService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  public shouldRedirectToLogin(): boolean {
    return !this.contextHolder.isLoggedIn;
  }

  public getFirstAvailablePage$(): Observable<string> {
    //@ts-ignore
    return this.navigationService.get1stAvailableRoute().pipe(map(url => url.toString()));
  }

  public redirectToPath(path: string): void {
    this.document.location.href = path;
  }

  /**
   * Redirect to login page and clean localstorage
   */
  public redirectToLoginPage(): void {
    this.contextHolder.invalidate();
    this.document.location.replace(this.authService.getLoginUrl());
  }
}
