<div class="header-container">
  <div class="header">
    <div
      class="header-cell"
      *ngFor="let col of columns"
      (click)="changeSort($any(col.sort))"
      [class.sortable]="col.sort"
      [class.current-sort]="col.sort === filter?.sort?.field"
      [style.width]="col.colWidth ? col.colWidth + 'px' : col.calculatedPercentage + '%'"
    >
      <span class="custom-checkbox" *ngIf="col.toggleAll" title="Select all">
        <label>
          <input type="checkbox" value="" (click)="changeToggleAll(col.toggleAll)" />
          <ng-container [ngTemplateOutlet]="toggleTemplate" [ngTemplateOutletContext]="{toggle: col.toggleAll}"></ng-container>
        </label>
      </span>
      <span class="custom-checkbox" *ngIf="col.toggleCurrentPage" title="Select current page">
        <label>
          <input type="checkbox" value="" (click)="changeToggleCurrentPage(col.toggleCurrentPage)" />
          <ng-container [ngTemplateOutlet]="toggleTemplate" [ngTemplateOutletContext]="{toggle: col.toggleCurrentPage}"></ng-container>
        </label>
      </span>
      <div class="col-label">{{ col.name }}</div>
      <i class="sort-icon" [ngClass]="getSortIcon(col.sort!)"></i>
      <i class="sort-hover fas fa-arrow-down"></i>
    </div>
  </div>
</div>

<ng-template #toggleTemplate let-toggle="toggle">
  <span [class.selected]="isToggleSelected(toggle)">
    <i *ngIf="toggle.status === undefined" [ngClass]="Icons.MINUS" class="toggle-icon"></i>
    <i *ngIf="toggle.status === true" [ngClass]="Icons.CHECK" class="toggle-icon"></i>
  </span>
</ng-template>
