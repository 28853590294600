import {Component} from '@angular/core';
import {ButtonVariants} from '@jtm/jtm-components';
import {UntilDestroy} from '@jumio/portals.core';
import {Subscription} from 'rxjs';
import {WelcomeRedirectService} from '../../services/redirect/redirect.service';

@UntilDestroy()
@Component({
  templateUrl: './error403.component.html',
  styleUrls: ['./error403.component.less']
})
export class Error403Component {
  public readonly subscription = new Subscription();
  public btnVariant = ButtonVariants.Secondary;

  constructor(private welcomeRedirectService: WelcomeRedirectService) {}

  public redirectToHomePage(): void {
    this.subscription.add(
      this.welcomeRedirectService.getFirstAvailableRoute$().subscribe({
        next: url => {
          this.welcomeRedirectService.directsToPath(url);
        },
        error: () => {
          this.welcomeRedirectService.directsToLoginPage();
        }
      })
    );
  }
}
