import {IProductTransactions} from '../monitor.interface';

export const productTransactionsResponseMock: IProductTransactions = {
  data: {
    dimensions: [1.6613856e12, 1.661472e12],
    categories: [
      {
        name: 'product',
        values: [9.0, 29.0],
        series: [
          {
            name: 'ID_IV',
            total: 2.0,
            percentage: 0.05263157894736842,
            values: [2.0, 0.0]
          },
          {
            name: 'GOVT_ID_VERIFICATION',
            total: 35.0,
            percentage: 0.9210526315789473,
            values: [7.0, 28.0]
          },
          {
            name: 'ADDRESS_VERIFICATION',
            total: 1.0,
            percentage: 0.02631578947368421,
            values: [0.0, 1.0]
          }
        ]
      }
    ],
    total: {
      value: 38.0,
      percentage: 38.0
    },
    average: {
      value: 9.0,
      percentage: 9.0
    }
  },
  context: {
    aggregatorFormat: 'day',
    monitorDataType: 'com.jumio.dashboardeventsaggregator.domain.CategoryMonitor',
    dimensionDataType: 'java.lang.Long',
    seriesDataType: 'java.lang.Double',
    startTime: 1661385600000,
    endTime: 1661731200000,
    historyStartTime: 1629849600000,
    historyEndTime: 1630195200000,
    durationInaggregatorFormat: 4,
    processedStartTime: 1663623645012,
    processedEndTime: 1663623647018,
    processedTime: 2006
  }
};
