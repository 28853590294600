import {IKycTransactions} from '../monitor.interface';

export const kycDecisionTransactionsResponseMock: IKycTransactions = {
  data: {
    dimensions: [1.6613856e12, 1.661472e12],
    categories: [
      {
        name: 'decision',
        values: [9.0, 37.0],
        series: [
          {
            name: 'PASSED',
            total: 23.0,
            percentage: 0.5,
            values: [0.0, 23.0]
          },
          {
            name: 'REJECTED',
            total: 22.0,
            percentage: 0.4782608695652174,
            values: [9.0, 13.0]
          },
          {
            name: 'WARNING',
            total: 1.0,
            percentage: 0.021739130434782608,
            values: [0.0, 1.0]
          }
        ]
      }
    ],
    total: {
      value: 46.0,
      percentage: 46.0
    },
    average: {
      value: 11.0,
      percentage: 11.0
    }
  },
  context: {
    aggregatorFormat: 'day',
    monitorDataType: 'com.jumio.dashboardeventsaggregator.domain.CategoryMonitor',
    dimensionDataType: 'java.lang.Long',
    seriesDataType: 'java.lang.Double',
    startTime: 1661385600000,
    endTime: 1661731200000,
    historyStartTime: 1629849600000,
    historyEndTime: 1630195200000,
    durationInaggregatorFormat: 4,
    processedStartTime: 1663640604637,
    processedEndTime: 1663640606058,
    processedTime: 1421
  }
};
