import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'repeat',
  standalone: true
})
export class RepeatPipe implements PipeTransform {
  public transform(value: any): Array<number> {
    return isNaN(value)
      ? []
      : Array(parseInt(value, 10))
          .fill(1)
          .map((n, idx) => idx);
  }
}
