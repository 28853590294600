export class ErrorUrls {
  public static readonly BASE = 'error';
  public static readonly ERROR_404 = '404';
  public static readonly ERROR_403 = '403';
}

export class LoginUrls {
  public static readonly AUTH0_AUTHORIZATION_CALLBACK = '';
  public static readonly LOGIN = 'login';
}

export class QuickTourUrls {
  public static readonly QUICK_TOUR = 'quick-tour';
}
