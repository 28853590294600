import {DestroyRef, Injectable, Signal, computed, inject, signal} from '@angular/core';

import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {firstValueFrom, tap} from 'rxjs';
import {FeatureFlag, FeatureFlags} from '../constants/feature-flags.constants';
import {FeatureFlagConfigService} from './http-services/feature-flag-config.service';

@Injectable({providedIn: 'root'})
export class FeatureFlagService {
  private featureFlagsServiceConfig = inject(FeatureFlagConfigService);
  public readonly destroyRef = inject(DestroyRef);

  public readonly featureFlagsState = signal({} as FeatureFlags);
  public readonly featureFlags: Signal<FeatureFlags> = computed(() => this.featureFlagsState());

  public async loadFeatureFlags(): Promise<FeatureFlags> {
    return await firstValueFrom(
      this.featureFlagsServiceConfig.loadFeatureFlagConfig$().pipe(
        takeUntilDestroyed(this.destroyRef),
        tap(featureFlag => {
          this.featureFlagsState.set({...featureFlag});
        })
      )
    );
  }

  public isEnabled(featureName: FeatureFlag | null): boolean {
    // If a value is not passed, then it should be visible
    if (!featureName) {
      return true;
    }

    return !!this.featureFlags()?.[featureName];
  }
}
