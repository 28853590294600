import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {DatadogRumLoggerService} from '@jumio/datadog-rum';
import {DatadogAppNames, UntilDestroy} from '@jumio/portals.core';
import {Subscription} from 'rxjs';

@UntilDestroy()
@Component({
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.less']
})
export class ErrorComponent implements OnInit {
  public readonly subscription = new Subscription();
  public title = 'Error';
  public displayNoAccessToTenantError = false;
  /**
   * Inject services dependencies
   * @param activatedRoute
   */
  constructor(public activatedRoute: ActivatedRoute, public datadogRumService: DatadogRumLoggerService) {}

  /**
   * Actions on component load
   */
  ngOnInit(): void {
    this.subscription.add(
      // Auth0 Errors are passed as queryParams
      this.activatedRoute.queryParams.subscribe(params => {
        if (!params?.error) {
          return;
        }

        const meta: Record<string, string> = {
          error: params.error,
          errorDescription: params.errorDescription,
          code: params.code,
          tenantId: params.tenantId
        };

        this.datadogRumService.logAction(`Error - ${params.error}`, {meta}, DatadogAppNames.UP_WELCOME);

        this.displayNoAccessToTenantError = true;
      })
    );
  }
}
