export const phoneRiskDecisionResponseMock = {
  data: {
    dimensions: [1660435200000, 1660521600000, 1660694400000, 1660780800000, 1660867200000, 1660953600000, 1661040000000],
    categories: [
      {
        name: 'status',
        series: [
          {
            name: 'Pass',
            total: 0.6,
            values: [0.1, 0.05, 0.05, 0.1, 0.1, 0.15, 0.05]
          },
          {
            name: 'Flag',
            total: 0.25,
            values: [0.05, 0, 0, 0.15, 0.02, 0, 0.03]
          },
          {
            name: 'Fail',
            total: 0.1,
            values: [0, 0.05, 0, 0, 0.0, 0.02, 0.03]
          },
          {
            name: 'Error',
            total: 0.05,
            values: [0.01, 0.02, 0.01, 0, 0.0, 0, 0.01]
          }
        ]
      },
      {
        name: 'rules',
        series: [
          {
            name: 'High phone risk score',
            triggered: 23,
            firing_rate: 0.5
          },
          {
            name: 'high phone risk score',
            triggered: 18,
            firing_rate: 0.3
          },
          {
            name: 'Phone Type - voip',
            triggered: 13,
            firing_rate: 0.1
          },
          {
            name: 'Phone recent sim swap',
            triggered: 12,
            firing_rate: 0.2
          },
          {
            name: 'Phone name mismatch',
            triggered: 6,
            firing_rate: 0.1
          },
          {
            name: 'Phone Type - voip',
            triggered: 6,
            firing_rate: 0.1
          }
        ]
      }
    ],
    volume: {
      value: 40.235,
      percentage: 0.2
    }
  },
  context: {
    aggregatorFormat: 'day'
  }
};
