import {Component, EventEmitter, Input, Output} from '@angular/core';
import * as moment from 'moment';
import {Subject} from 'rxjs';
import {ActiveRange} from '../../daterangepicker/date-range-wrapper.directive';

@Component({
  selector: 'app-dropdown-select',
  templateUrl: './dropdown-select.component.html',
  styleUrls: ['./dropdown-select.component.less']
})
export class DropdownSelectComponent<T> {
  @Input() public direction = 'down';
  @Input() public type = 'normal';

  @Input() public values: T[] | undefined;
  @Input() public hasDaterangePicker = false;
  @Input() public emptyIncluded = false;
  @Input() public emptyLabel = 'None';

  // Extra title (comment) for the user regarding what this dropdown is used for (can be empty)
  // It will be shown in the dropdown's top position
  @Input() public dropdownTitle: string = '';

  @Output() public onValueSelect = new EventEmitter<T | null>();

  private DATE_FORMAT = 'YYYY-MM-DD';

  public closeTrigger = new Subject<void>();
  public dateRangePickerConfig = {
    linkedCalendars: false,
    autoApply: false,
    showDropdowns: true,
    minDate: moment.utc().subtract(15, 'year'),
    maxDate: moment.utc().endOf('day'),
    drops: 'down',
    opens: 'left',
    buttonClasses: 'button button-inline',
    cancelButtonClasses: 'button-negative'
  };
  public selectedCustomRange: T | ActiveRange | undefined | null;

  public selectValue(value: T | null): void {
    this.selectedCustomRange = value;
    this.emitValue(value);
  }

  public selectCustomRange(value: ActiveRange): void {
    const response = {
      label: `${moment.utc(value.start).format(this.DATE_FORMAT)} - ${moment.utc(value.end).format(this.DATE_FORMAT)}`,
      from: moment.utc(value.start),
      to: moment.utc(value.end)
    };
    this.selectedCustomRange = value;
    this.emitValue(response as unknown as T);
  }

  private emitValue(value: T | null): void {
    this.onValueSelect.next(value);
    this.closeTrigger.next();
  }
}
