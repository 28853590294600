import {Directive, forwardRef, Input} from '@angular/core';
import {NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';

@Directive({
  selector: '[appDomainValidator][ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DomainValidatorDirective),
      multi: true
    }
  ],
  standalone: true
})
export class DomainValidatorDirective implements Validator {
  @Input('appDomainValidator') public enabled: boolean = false;

  public validate(control: any): ValidationErrors | null {
    if (this.enabled && control.value) {
      if (
        this.isHttps(control.value) &&
        this.isNotIpAddress(control.value) &&
        this.checkForbiddenWords(control.value) &&
        this.checkFragmentIndicator(control.value) &&
        this.checkDot(control.value) &&
        this.isNotLastDot(control.value) &&
        this.isValidUrl(control.value)
      ) {
        return null;
      }
      return {wrongUrl: true};
    }
    return null;
  }

  public isHttps(value: string): boolean {
    return /^https:\/\//.test(value);
  }

  public isNotIpAddress(value: string): boolean {
    return !/(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])/.test(value);
  }

  public checkForbiddenWords(value: string): boolean {
    return !/transactionStatus=|customerInternalReference=|transactionReference=|errorCode=/.test(value);
  }

  public checkFragmentIndicator(value: string): boolean {
    return !/#/.test(value);
  }

  public checkDot(value: string): boolean {
    return /\./.test(value);
  }

  public isNotLastDot(value: string): boolean {
    return !/\.$/.test(value);
  }

  public isValidUrl(value: string): boolean {
    return /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/.test(
      value
    );
  }
}
