import {Component, NgZone, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {JtmSecurityContextHolder} from '@jtm/jtm-services';
import {UntilDestroy} from '@jumio/portals.core';
import {Subscription} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {WelcomeRedirectService} from '../../services/redirect/redirect.service';

@UntilDestroy()
@Component({
  templateUrl: './auth0-callback.component.html',
  styleUrls: ['./auth0-callback.component.less']
})
export class Auth0CallbackComponent implements OnInit {
  public readonly subscription = new Subscription();
  /**
   * Request in progress
   */
  public isBusy = false;

  constructor(
    protected welcomeRedirectService: WelcomeRedirectService,
    protected route: ActivatedRoute,
    protected zone: NgZone,
    private securityContextHolder: JtmSecurityContextHolder
  ) {
    const {code = '', tenant = '', error = '', error_description = ''} = this.route.snapshot.queryParams;

    this.welcomeRedirectService.setParams({code, tenant, error, errorDescription: error_description});
  }

  ngOnInit(): void {
    this.welcomeRedirectService.hasError() ? this.excuteWhenHasError() : this.excuteWhenHasNoError();
  }

  private excuteWhenHasError(): void {
    this.directsToErrorPage();
  }

  private excuteWhenHasNoError(): void {
    this.welcomeRedirectService.hasTenantId() && this.welcomeRedirectService.hasCode()
      ? this.excuteWhenHasTenantAndCode()
      : this.excuteWhenHasNoTenantAndCode();
  }

  private excuteWhenHasTenantAndCode(): void {
    this.setCurrentUser();
  }

  private excuteWhenHasNoTenantAndCode(): void {
    if (this.welcomeRedirectService.hasAccessToken()) {
      this.directsToQuickTourOrFirstAvailableRoute();
    } else {
      this.zone.run(() => {
        this.welcomeRedirectService.directsToLoginPage();
      });
    }
  }

  /**
   * In case of error query param present in Auth0 callback URL directs to error page
   */
  private directsToErrorPage(): void {
    this.welcomeRedirectService.directsToErrorPage();
  }

  /**
   * In case of access token present in local storage, try to directs user to the 1st available j4 page
   */
  private directsToFirstAvailableRoute(): void {
    this.subscription.add(
      this.welcomeRedirectService.getFirstAvailableRoute$().subscribe({
        next: url => {
          this.zone.run(() => {
            this.welcomeRedirectService.directsToPath(url);
          });
        },
        error: err => {
          this.zone.run(() => {
            this.welcomeRedirectService.directsToLoginPage({error: err.status, message: err.error, errorUrl: err.url});
          });
        }
      })
    );
  }

  /**
   * In case of tenantId and one time code present in Auth0 callback url, fetch/set current-user,
   * and directs user to 1st available j4 page otherwise in case of any error directs back to login page
   */
  private setCurrentUser(): void {
    // Invalidate token before fetching a new one
    // Endpoint will fail if token is invalid, even though it's not needed at this point
    this.securityContextHolder.invalidate();
    this.isBusy = true;

    this.subscription.add(
      this.welcomeRedirectService
        .getCurrentUser$()
        .pipe(switchMap(() => this.welcomeRedirectService.getFirstAvailableRouteOrQuickTour$()))
        .subscribe({
          next: url => {
            this.zone.run(() => {
              this.welcomeRedirectService.directsToPath(url);
            });
          },
          error: err => {
            this.zone.run(() => {
              this.welcomeRedirectService.directsToLoginPage({error: err.status, message: err.error, errorUrl: err.url});
            });
          }
        })
    );
  }

  private directsToQuickTourOrFirstAvailableRoute(): void {
    this.subscription.add(
      this.welcomeRedirectService.shouldShowQuickTour$().subscribe({
        next: show => {
          if (show) {
            this.zone.run(() => {
              this.welcomeRedirectService.directsToQuickTourPage();
            });
          } else {
            this.directsToFirstAvailableRoute();
          }
        },
        error: () => {
          this.directsToFirstAvailableRoute();
        }
      })
    );
  }
}
