import {Component, OnInit, ViewChild} from '@angular/core';
import {UntilDestroy} from '@jumio/portals.core';
import {Observer, Subscription} from 'rxjs';
import {ModalComponent} from '@jumio/portals.core-ui';
import {UnsavedChangesService} from 'shared/services/unsaved-changes/unsaved-changes.service';

@UntilDestroy()
@Component({
  selector: 'app-unsaved-changes-modal',
  templateUrl: './unsaved-changes-modal.component.html'
})
/**
 * This component shows the modal window for warning the user that there are unsaved changes on the given page.
 */
export class UnsavedChangesModalComponent implements OnInit {
  /**
   * The reference to the modal window.
   */
  @ViewChild('modal') public modal: ModalComponent | undefined;
  /**
   * An observer which will notify the unsavedChangesGuard about the user's decision.
   */
  public observer: Observer<boolean> | undefined;

  public readonly subscription = new Subscription();

  constructor(private unsavedChangesService: UnsavedChangesService) {}

  /**
   * Initializes the component.
   * When the unsavedChangesService emits a new event, the modal will show up and take the Observer of the guard.
   */
  ngOnInit(): void {
    this.subscription.add(
      this.unsavedChangesService.onCheckUnsavedChanges$.subscribe((observer: Observer<boolean>) => {
        this.modal?.show();
        this.observer = observer;
      })
    );
  }
}
