<app-form-group
  [label]="label"
  [name]="name"
  [errorMessages]="errorMessages"
  [customValidation]="isCustomValidationInput"
  [customValidators]="validators"
  (click)="onInputClick($event)"
  [classes]="classes"
>
  <div #formElement class="display-flex full-width" [class.disabled]="disabled || (roles! | isRolesDisabled)" ngProjectAs=".form-element">
    <div class="position-relative flex-1" triggers="focus" [popover]="popoverTemplate" placement="bottom">
      <ng-container *ngIf="isSimpleInput">
        <input
          class="form-group-input"
          trim="blur"
          [placeholder]="placeholder"
          [class.has-addon]="addOn.children.length"
          [class.placeholder-hidden]="value === 0 ? true : value"
          [class.has-error]="model.errors && model.touched"
          #model="ngModel"
          [(ngModel)]="value"
          [id]="name"
          [name]="name"
          [pattern]="pattern!"
          [email]="isEmailInput && !!value"
          [autocomplete]="autocomplete"
          [type]="type"
          [required]="required"
          [maxlength]="maxlength!"
          [appIsEnabled]="roles"
          [min]="min"
          [max]="max"
          [step]="step"
          (change)="onChange($event)"
          (keyup)="onChange($event)"
          (blur)="onBlur()"
          (focus)="propagateTouch()"
          [appDomainValidator]="$any(validators['appDomainValidator'])"
        />
        <label class="form-group-input-label" [class.has-error]="labelHasError" [for]="name">{{ label }}</label>
      </ng-container>
      <app-natural-number-input
        *ngIf="isNaturalNumberInput"
        [name]="name"
        [placeholder]="placeholderText"
        [class.has-error]="naturalModel.errors && naturalModel.touched"
        [hasAddon]="addOn.children.length"
        [placeholderHidden]="value === 0 ? true : value"
        [(ngModel)]="value"
        [required]="required"
        [appIsEnabled]="roles"
        [disabled]="disabled"
        [min]="min!"
        [max]="max"
        [maxLength]="maxlength!"
        (change)="onChange($event)"
        #naturalModel="ngModel"
        #naturalNumberComponent
      >
        <label
          class="form-group-input-label"
          [class.has-error]="naturalModel.errors && naturalModel.touched"
          ngProjectAs=".form-group-input-label"
          [for]="name"
          >{{ label }}</label
        >
        <ng-template #errorsTemplate>
          <ng-container [ngTemplateOutlet]="$any(naturalNumberComponent.errorsTemplate)"></ng-container>
        </ng-template>
      </app-natural-number-input>
      <div *ngIf="!disabled && required && !value && value !== 0"></div>
      <small class="input-comment" *ngIf="inputComment">{{ inputComment }}</small>
    </div>
    <div #addOn>
      <ng-content select=".form-group-addon"></ng-content>
    </div>
  </div>

  <ng-content select=".form-group-note" ngProjectAs=".note"></ng-content>
  <ng-content #appError select="#appError" ngProjectAs="j4-alert"></ng-content>
</app-form-group>
