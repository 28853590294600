<div #container class="data-table-container">
  <ng-content select="filter"></ng-content>
  <ng-content select="action-buttons"></ng-content>
  <div *ngIf="items" class="data-table" [class.bottom-padding]="!filter || !maxItemCount">
    <app-header
      *ngIf="isHeaderVisible()"
      [filter]="filter"
      [columns]="columns"
      (sortEvent)="changeSort($any($event))"
      (toggleAll)="changeToggleAll($any($event))"
      (toggleCurrentPage)="changeToggleCurrentPage($event)"
    ></app-header>

    <ng-container *ngIf="items.length">
      <app-card-view
        class="body"
        *ngIf="isCardView()"
        [items]="items"
        [columns]="columns"
        [sortedField]="filter?.sort?.field"
        [hasNavigation]="hasNavigation"
        (rowClick)="rowClick($any($event))"
      >
      </app-card-view>
      <app-list-view
        class="body"
        *ngIf="isListView()"
        [items]="items"
        [columns]="columns"
        [sortedField]="filter?.sort?.field"
        [hasNavigation]="hasNavigation"
        (rowClick)="rowClick($any($event))"
      >
      </app-list-view>
    </ng-container>

    <div class="body" *ngIf="!items.length">
      <div class="placeholder" *ngIf="!items.length">No results found with the provided criteria</div>
    </div>

    <app-data-table-paginator
      *ngIf="filter && maxItemCount"
      [maxItemCount]="maxItemCount"
      [filter]="filter"
      (onPageChange)="pageChanged()"
    ></app-data-table-paginator>
  </div>
</div>
