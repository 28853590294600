export const deviceRiskDecisionResponseMock = {
  data: {
    dimensions: [1660435200000, 1660521600000, 1660694400000, 1660780800000, 1660867200000, 1660953600000, 1661040000000],
    categories: [
      {
        name: 'status',
        series: [
          {
            name: 'Pass',
            total: 0.4,
            values: [0.07, 0.05, 0.1, 0.03, 0.1, 0.0, 0.05]
          },
          {
            name: 'Flag',
            total: 0.3,
            values: [0.05, 0, 0.05, 0, 0, 0.15, 0.5]
          },
          {
            name: 'Fail',
            total: 0.2,
            values: [0.0, 0.1, 0, 0.05, 0, 0, 0.05]
          },
          {
            name: 'Error',
            total: 0.1,
            values: [0.05, 0.0, 0.0, 0.05, 0, 0, 0]
          }
        ]
      },
      {
        name: 'rules',
        series: [
          {
            name: 'IP Risky',
            triggered: 100,
            firing_rate: 0.1
          },
          {
            name: 'High VPN Risk',
            triggered: 18,
            firing_rate: 0.03
          },
          {
            name: 'High Proxy Risk',
            triggered: 13,
            firing_rate: 0.02
          },
          {
            name: 'Emulator Risk',
            triggered: 12,
            firing_rate: 0.02
          },
          {
            name: 'OS Mismatch High Risk',
            triggered: 6,
            firing_rate: 0.01
          },
          {
            name: 'High Risk Score',
            triggered: 23,
            firing_rate: 0.05
          },
          {
            name: 'Phone Type Invalid',
            triggered: 18,
            firing_rate: 0.3
          },
          {
            name: 'Phone Type - Prepaid',
            triggered: 13,
            firing_rate: 0.2
          },
          {
            name: 'Name/Phone Invalid',
            triggered: 12,
            firing_rate: 0.2
          },
          {
            name: 'Name/Email Invalid',
            triggered: 6,
            firing_rate: 0.1
          },
          {
            name: 'Email',
            triggered: 23,
            firing_rate: 0.05
          },
          {
            name: 'Domain not valid',
            triggered: 18,
            firing_rate: 0.3
          },
          {
            name: 'Email tenure low',
            triggered: 13,
            firing_rate: 0.2
          },
          {
            name: 'Email tumbling flag',
            triggered: 12,
            firing_rate: 0.2
          },
          {
            name: 'Disposable email',
            triggered: 6,
            firing_rate: 0.1
          },
          {
            name: 'High phone risk score',
            triggered: 23,
            firing_rate: 0.5
          },
          {
            name: 'high phone risk score',
            triggered: 18,
            firing_rate: 0.3
          },
          {
            name: 'Phone Type - voip',
            triggered: 13,
            firing_rate: 0.1
          }
        ]
      }
    ],
    volume: {
      value: 39.175,
      percentage: 0.2
    }
  },
  context: {
    aggregatorFormat: 'day'
  }
};
