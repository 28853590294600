import {LOCALSTORAGE_CONST} from '../constants/localstorage.constants';
import {ICurrentUser} from '../interfaces/localstorage.interface';

export const getCurrentTenantName = (): string => {
  const currentUser: ICurrentUser = JSON.parse(localStorage.getItem(LOCALSTORAGE_CONST.CURRENT_USER) ?? '{}');
  const currentTenantId = currentUser.tenantId;

  return currentUser.tenants?.filter((t: {tenantId: string; tenantName: string}) => t.tenantId === currentTenantId)[0]?.tenantName;
};

export const getCurrentTenantId = (): string => {
  const currentUser: ICurrentUser = JSON.parse(localStorage.getItem(LOCALSTORAGE_CONST.CURRENT_USER) ?? '{}');
  return currentUser.tenantId;
};

export const getCurrentUserEmail = (): string => {
  const currentUser: ICurrentUser = JSON.parse(localStorage.getItem(LOCALSTORAGE_CONST.CURRENT_USER) ?? '{}');
  return currentUser.email;
};

export const getCurrentUser = (): ICurrentUser => {
  const currentUser: ICurrentUser = JSON.parse(localStorage.getItem(LOCALSTORAGE_CONST.CURRENT_USER) ?? '{}');
  return currentUser;
};
