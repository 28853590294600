import {Pipe, PipeTransform} from '@angular/core';
import {SecurityContextHolder} from 'shared/services/security/security-context-holder';

@Pipe({
  name: 'isRolesDisabled',
  standalone: true
})
/**
 * A simple pipe that returns a boolean value depending on the required input permissions.
 */
export class IsRolesDisabled implements PipeTransform {
  /**
   * @param {SecurityContextHolder} contextHolder
   */
  constructor(private contextHolder: SecurityContextHolder) {}

  /**
   * Takes a string or a string array as an input, which contains the permissions.
   * Returns true, if the user does not have any of the required permissions.
   * Returns false, if the user has at least one of the required permissions.
   * @param {string | Array<string>} value
   * @param args
   * @returns {boolean}
   */
  transform(value: string | string[]): boolean {
    if (value) {
      const roles = value instanceof Array ? value : value.split(',');
      return !roles.some(role => this.contextHolder.authorities.indexOf(role.trim()) >= 0);
    }
    return false;
  }
}
