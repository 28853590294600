import {NgModule} from '@angular/core';
import {ActivatedRouteSnapshot, RouterModule, RouterStateSnapshot, Routes} from '@angular/router';
import {DashboardRootComponent} from '@jumio/portals.dashboard';
import {RootComponent, UP_FEATURE_PATHS, UP_RULES_FEATURE_PATHS} from '@jumio/portals.up-shared';
import {environment} from '../environments/environment';
import {Auth0CallbackComponent} from './containers/auth0-callback/auth0-callback.component';
import {ErrorComponent} from './containers/error/error.component';
import {ErrorUrls, LoginUrls, QuickTourUrls} from './services/route-url-constants';
import {redirectGuard} from './guards/redirect.guard';
import {Error403Component} from './containers/error403/error403.component';
import {QuickTourComponent} from './containers/quick-tour/quick-tour.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: UP_FEATURE_PATHS.welcome
  },
  {
    path: UP_FEATURE_PATHS.welcome,
    children: [
      {
        path: LoginUrls.AUTH0_AUTHORIZATION_CALLBACK,
        component: Auth0CallbackComponent,
        data: {title: 'Auth0 callback'}
      },
      {
        path: ErrorUrls.BASE,
        component: ErrorComponent,
        data: {title: 'Error'}
      },
      {
        path: ErrorUrls.ERROR_403,
        component: Error403Component,
        data: {title: 'Error'}
      },
      {
        path: QuickTourUrls.QUICK_TOUR,
        component: QuickTourComponent,
        data: {title: 'Quick Tour'},
        canActivate: [redirectGuard]
      }
    ]
  },
  {
    path: UP_FEATURE_PATHS.settings,
    component: RootComponent,
    data: {
      title: 'Settings'
    },
    children: [
      {path: '', loadChildren: () => import('libs/portals/feature/settings/src/settings.module').then(m => m.SettingsFeatureModule)}
    ]
  },
  {
    path: UP_FEATURE_PATHS.verificationReports,
    component: RootComponent,
    data: {
      title: 'Verification Reports'
    },
    children: [
      {
        path: '',
        loadChildren: () =>
          import('libs/portals/feature/verification-reports/src/lib/verification-reports.module').then(m => m.VerificationReportsModule)
      }
    ]
  },
  {
    path: UP_FEATURE_PATHS.verifications,
    component: RootComponent,
    data: {
      title: 'Verifications'
    },
    children: [
      {
        path: '',
        loadChildren: () => import('libs/portals/verification/src/lib/verification.module').then(m => m.VerificationModule)
      }
    ]
  },
  {
    path: UP_FEATURE_PATHS.reports,
    component: RootComponent,
    data: {
      title: 'Reports'
    },
    children: [
      {
        path: '',
        loadChildren: () => import('libs/portals/feature/kyx-reports/src/lib/app.module').then(m => m.KyxReportsModule)
      }
    ]
  },
  {
    path: UP_FEATURE_PATHS.dashboard,
    component: DashboardRootComponent,
    data: {
      title: 'Dashboard'
    },
    children: [
      {
        path: '',
        loadChildren: () => import('libs/portals/feature/dashboard/src/lib/dashboard.module').then(m => m.DashboardModule)
      }
    ]
  },
  // these routes are only necessary until these applications are integrated into the monorepo and the application itself
  {
    path: UP_FEATURE_PATHS.explorer,
    canActivate: [
      (): false => {
        window.location.href = '/explorer';
        return false;
      }
    ],
    children: []
  },
  {
    path: UP_FEATURE_PATHS.rules,
    children: [
      {path: UP_RULES_FEATURE_PATHS.globalRulesetList, children: []},
      {path: UP_RULES_FEATURE_PATHS.rulesetList, children: []},
      {path: UP_RULES_FEATURE_PATHS.globalVelocities, children: []},
      {path: UP_RULES_FEATURE_PATHS.globalVelocitySets, children: []},
      {path: UP_RULES_FEATURE_PATHS.customFields, children: []},
      {path: UP_RULES_FEATURE_PATHS.admin, children: []}
    ],
    canActivate: [
      (_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): false => {
        window.location.href = state.url;
        return false;
      }
    ]
  },
  {
    path: UP_FEATURE_PATHS.transactionsCreate,
    canActivate: [
      (): false => {
        window.location.href = '/transactions-create';
        return false;
      }
    ],
    children: []
  },
  {
    path: '**',
    data: {title: 'Not found'},
    component: Error403Component,
    canActivate: [redirectGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      enableTracing: environment.enableTracing ?? !environment.production
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
