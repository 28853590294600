<div class="paginator-container">
  <div class="paginator">
    <div class="col-md-2">
      <div class="paginator-label">
        {{ paginatorLabel }}
        <span class="tooltiptext" *ngIf="tooltipLabel">{{ tooltipLabel }}</span>
      </div>
    </div>
    <div class="col-md-7 text-center">
      <div class="page-counter" *ngIf="maxItemCount && getNumberOfPages() > 1">
        <button
          type="button"
          class="button button-small navigation-button"
          [disabled]="getCurrentPageNumber() === 1"
          (click)="previousPage()"
        >
          <i [ngClass]="Icons.ANGLE_LEFT"></i>
        </button>
        <div class="page-numbers">
          <div class="page-number" [class.active]="1 === getCurrentPageNumber()" (click)="setCurrentPage(1)">1</div>

          <!-- Only display left side placeholder if there are more than 6 pages and we are beyond the 5th page. -->
          <div class="page-placeholder" *ngIf="displayLowerPlaceholder()">...</div>

          <ng-container *ngFor="let n of getNumberOfPages() - 2 | repeat">
            <!-- We display the first and last elements separately, so we need to add 2 to the counter -->
            <div
              class="page-number"
              *ngIf="shouldDisplayPageNumber(n + 2)"
              [class.active]="n + 2 === getCurrentPageNumber()"
              (click)="setCurrentPage(n + 2)"
            >
              {{ n + 2 }}
            </div>
          </ng-container>

          <!-- Only display right side placeholder if there are more than 9 pages and we are further than 4 pages away from the last page. -->
          <div class="page-placeholder" *ngIf="displayUpperPlaceholder()">...</div>

          <div
            class="page-number"
            [class.active]="getNumberOfPages() === getCurrentPageNumber()"
            (click)="setCurrentPage(getNumberOfPages())"
          >
            {{ getNumberOfPages() }}
          </div>
        </div>
        <button
          type="button"
          class="button button-small navigation-button"
          (click)="nextPage()"
          [disabled]="filter.offset + filter.pageSize >= maxItemCount"
        >
          <i [ngClass]="Icons.ANGLE_RIGHT"></i>
        </button>
      </div>
    </div>
    <div class="col-md-3">
      <div class="result-box pull-right" *ngIf="maxItemCount">
        <div class="result-per-page-label">Results per page:</div>
        <app-dropdown-select (onValueSelect)="changePageSize($any($event))" type="narrow" [values]="pageSizes" direction="up right">
          <button type="button" class="button button-small dropdown-button">
            {{ filter.pageSize }}
            <i [ngClass]="Icons.ANGLE_DOWN"></i>
          </button>
        </app-dropdown-select>
      </div>
    </div>
  </div>
</div>
