<div data-cy="app-modal-container" class="modal fade" bsModal #modal="bs-modal" role="dialog" [config]="modalOptions" tabindex="-1">
  <div data-cy="app-modal-dialog" class="modal-dialog modal-md">
    <div class="modal-content">
      <div data-cy="app-modal-header" class="modal-header">
        <h4 data-cy="app-modal-header-title" class="modal-title pull-left">{{ title }}</h4>
        <button data-cy="app-modal-header-button" type="button" class="close pull-right" aria-label="Close" (click)="close()">
          <span>&times;</span>
        </button>
      </div>
      <div data-cy="app-modal-body" class="modal-body">
        <ng-content></ng-content>
      </div>
      <ng-content data-cy="app-modal-footer" select=".modal-footer"></ng-content>
    </div>
  </div>
</div>
