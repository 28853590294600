<app-modal-confirm
  [title]="'Warning!'"
  confirmText="Leave page"
  cancelText="No"
  (onCancel)="observer?.next(false)"
  (onClose)="observer?.next(false)"
  (onConfirm)="observer?.next(true)"
  #modal
>
  There are unsaved changes! Do you want to leave this page?
</app-modal-confirm>
