import {ISars} from '../monitor.interface';

export const oldestOpenCasesMock: ISars = {
  data: {
    total: 12,
    categories: [
      {
        name: 'open',
        items: [
          {
            name: 'AML-003277',
            value: 'Time Open: 20 days',
            tags: [
              {
                name: 'STRUCTURING',
                type: 'structuring'
              },
              {
                name: 'FLOW-THROUGH',
                type: 'flow'
              }
            ]
          },
          {
            name: 'AML-003987',
            value: 'Time Open: 18 days',
            tags: [
              {
                name: 'FLOW-THROUGH',
                type: 'flow'
              }
            ]
          },
          {
            name: 'AML-004837',
            value: 'Time Open: 12 days',
            tags: [
              {
                name: 'DORMANT PARTY',
                type: 'dormant'
              },
              {
                name: 'STRUCTURING',
                type: 'structuring'
              },
              {
                name: 'FLOW-THROUGH',
                type: 'flow'
              }
            ]
          },
          {
            name: 'AML-113277',
            value: 'Time Open: 65 days',
            tags: [
              {
                name: 'DORMANT PARTY',
                type: 'dormant'
              },
              {
                name: 'STRUCTURING',
                type: 'structuring'
              },
              {
                name: 'FLOW-THROUGH-2',
                type: 'flow'
              },
              {
                name: 'FLOW-THROUGH-3',
                type: 'flow'
              },
              {
                name: 'FLOW-THROUGH-4',
                type: 'flow'
              }
            ]
          },
          {
            name: 'AML-113987',
            value: 'Time Open: 78 days',
            tags: [
              {
                name: 'DORMANT PARTY',
                type: 'dormant'
              }
            ]
          },
          {
            name: 'AML-114837',
            value: 'Time Open: 56 days',
            tags: [
              {
                name: 'STRUCTURING',
                type: 'structuring'
              },
              {
                name: 'FLOW-THROUGH',
                type: 'flow'
              }
            ]
          },
          {
            name: 'AML-223277',
            value: 'Time Open: 14 days',
            tags: [
              {
                name: 'STRUCTURING',
                type: 'structuring'
              },
              {
                name: 'FLOW-THROUGH',
                type: 'flow'
              }
            ]
          },
          {
            name: 'AML-223987',
            value: 'Time Open: 18 days',
            tags: [
              {
                name: 'FLOW-THROUGH',
                type: 'flow'
              }
            ]
          },
          {
            name: 'AML-224837',
            value: 'Time Open: 90 days',
            tags: [
              {
                name: 'STRUCTURING',
                type: 'structuring'
              },
              {
                name: 'FLOW-THROUGH',
                type: 'flow'
              }
            ]
          },
          {
            name: 'AML-334837',
            value: 'Time Open: 66 days',
            tags: [
              {
                name: 'DORMANT PARTY',
                type: 'dormant'
              },
              {
                name: 'STRUCTURING',
                type: 'structuring'
              },
              {
                name: 'FLOW-THROUGH',
                type: 'flow'
              }
            ]
          }
        ]
      }
    ]
  },
  context: {
    aggregatorFormat: 'day',
    monitorDataType: 'com.jumio.dashboardeventsaggregator.domain.CategoryMonitor',
    dimensionDataType: 'java.lang.Long',
    seriesDataType: 'java.lang.Double',
    startTime: 1661385600000,
    endTime: 1661731200000,
    historyStartTime: 1629849600000,
    historyEndTime: 1630195200000,
    durationInaggregatorFormat: 4,
    processedStartTime: 1663640878564,
    processedEndTime: 1663640880372,
    processedTime: 1808
  }
};
