import {Component, EventEmitter, Input, Output} from '@angular/core';
import * as _ from 'lodash-es';
import {CodeLabel} from 'public-shared/models/code-label/code-label.dto';
import {TableConstants} from 'shared/components/table/table.constants';
import {IconConstants} from 'shared/services/icon.constants';

@Component({
  selector: 'app-data-table-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.less']
})
export class PaginatorComponent {
  @Input() public filter: any;
  @Input() public maxItemCount: number | undefined;
  @Output() public onPageChange = new EventEmitter<void>();

  public readonly Icons = IconConstants.ICONS;
  public pageNumber = 1;
  public pageSizes: CodeLabel[] = [
    {
      code: '20',
      label: '20'
    },
    {
      code: '50',
      label: '50'
    }
  ];

  public setCurrentPage(n: number): void {
    /**
     * for the sake of simplicity the function gets the desired page's number, so we need to subtract
     * 1 from it as offset starts from 0
     */
    const offset = (n - 1) * this.filter.pageSize;
    if (offset !== this.filter.offset) {
      this.pageNumber = n;
      this.filter.offset = offset;
      this.onPageChange.next();
    }
  }

  public previousPage(): void {
    this.setCurrentPage(this.getCurrentPageNumber() - 1);
  }

  public nextPage(): void {
    this.setCurrentPage(this.getCurrentPageNumber() + 1);
  }

  public jumpToPage(): void {
    this.pageNumber = _.clamp(this.pageNumber, 1, this.getNumberOfPages());
    this.setCurrentPage(this.pageNumber);
  }

  public changePageSize(pageSize: CodeLabel): void {
    this.filter.offset = 0;
    //@ts-ignore
    this.filter.pageSize = parseInt(pageSize.code, 10);
    this.onPageChange.next();
  }

  public getCurrentPageNumber(): number {
    return 1 + this.filter.offset / this.filter.pageSize;
  }

  public getNumberOfPages(): number {
    //@ts-ignore
    return Math.ceil(this.maxItemCount / this.filter.pageSize);
  }

  public displayLowerPlaceholder(): boolean {
    return this.getNumberOfPages() > 9 && this.getCurrentPageNumber() > 5;
  }

  public displayUpperPlaceholder(): boolean {
    return this.getNumberOfPages() > 9 && this.getCurrentPageNumber() < this.getNumberOfPages() - 4;
  }

  public shouldDisplayPageNumber(n: number): boolean {
    if (this.getNumberOfPages() <= 10) {
      return true;
    } else if (n < this.getCurrentPageNumber()) {
      // we have to display more elements if the current page number is near max to ensure that we have 9 items visible
      const adjustedLimit = 3 + Math.max(0, 4 - (this.getNumberOfPages() - this.getCurrentPageNumber()));
      return this.displayLowerPlaceholder() ? n > this.getCurrentPageNumber() - adjustedLimit : true;
    } else {
      // we have to display more elements if the current page number is near 1 to ensure that we have 9 items visible
      const adjustedLimit = 3 + Math.max(0, 5 - this.getCurrentPageNumber());
      return this.displayUpperPlaceholder() ? n < this.getCurrentPageNumber() + adjustedLimit : true;
    }
  }

  public onInputValueChange(value: number): void {
    this.pageNumber = value;
  }

  /**
   * The max item truncation is solely for the UI, not touching the actual number
   */
  public get maxItemCountString(): string | number {
    //@ts-ignore
    return this.maxItemCount >= TableConstants.MAX_SERVER_RESPONSE_DATA ? TableConstants.MAX_SERVER_RESPONSE_DATA + '+' : this.maxItemCount;
  }

  public get tooltipLabel(): string {
    //@ts-ignore
    return this.maxItemCount >= TableConstants.MAX_SERVER_RESPONSE_DATA ? '+ (More results possible - refine your filter)' : '';
  }

  public get maxItemOrOffset(): number {
    //@ts-ignore
    return this.maxItemCount < this.filter.offset + this.filter.pageSize ? this.maxItemCount : this.filter.offset + this.filter.pageSize;
  }

  public get paginatorLabel(): string {
    return `${this.filter.offset + 1} - ${this.maxItemOrOffset} of ${this.maxItemCountString}`;
  }
}
