import {NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {welcomeDeclarations, welcomeImports, welcomeProviders} from './constants/app-module.constants';

@NgModule({
  declarations: [AppComponent, ...welcomeDeclarations],
  imports: [...welcomeImports],
  providers: welcomeProviders,
  bootstrap: [AppComponent]
})
export class AppModule {}
