export const UP_FEATURE_PATHS = {
  welcome: 'welcome',
  settings: 'settings',
  verificationReports: 'verification-reports',
  verifications: 'verifications',
  reports: 'reports',
  dashboard: 'dashboard',
  explorer: 'explorer',
  rules: 'rules',
  transactionsCreate: 'transactions-create'
} as const;

export const UP_RULES_FEATURE_PATHS = {
  globalRulesetList: 'global-ruleset-list',
  rulesetList: 'ruleset-list',
  globalVelocities: 'velocity/global-velocities',
  globalVelocitySets: 'velocity/global-velocity-sets',
  customFields: 'custom-fields/designer',
  admin: 'settings'
} as const;

export type UpFeaturePaths = (typeof UP_FEATURE_PATHS)[keyof typeof UP_FEATURE_PATHS];
