<section class="j4-edit-widget-modal">
  <section class="j4-edit-widget__modal-card-body">
    <div class="j4-edit-widget__modal-content-left">
      <div class="j4-edit-widget__modal-content">
        <h3>Edit Widgets</h3>
        <p>Select which widgets you would like to add/remove to this page. You may change this later.</p>
        <j4-tree-checklist (newImagEvent)="changeImage($event)"></j4-tree-checklist>
      </div>
    </div>
    <div class="j4-edit-widget__modal-content-right">
      <div>
        <img [src]="image" alt="image" />
      </div>
    </div>
  </section>
  <footer class="j4-edit-widget__modal-card-footer">
    <div></div>
    <div></div>
    <div>
      <label (click)="closePanel()">Cancel</label>
      <jtm-button (clicked)="saveChanges()" [label]="'Apply'" [disabled]="!activeButtonSave"> </jtm-button>
    </div>
  </footer>
</section>
