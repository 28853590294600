import {Component, OnInit, inject} from '@angular/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {QuickTourService} from '@jtm/jtm-services';
import {QuickTourSliderItem, QuickTourVersion} from '@jtm/jtm-services/lib/services/quick-tour/quick-tour.interface';
import {UntilDestroy} from '@jumio/portals.core';
import {Subscription} from 'rxjs';
import {WelcomeRedirectService} from '../../services/redirect/redirect.service';
import {QuickTourViewComponent} from './quick-tour-view/quick-tour-view.component';

@UntilDestroy()
@Component({
  selector: 'app-quick-tour',
  template: ''
})
export class QuickTourComponent implements OnInit {
  private welcomeRedirectService = inject(WelcomeRedirectService);
  private quickTourService = inject(QuickTourService);
  private dialog = inject(MatDialog);

  public quickTourVersionDate: QuickTourVersion | undefined;
  public quickTourSliderItems: QuickTourSliderItem[] = [];
  public readonly subscription = new Subscription();

  ngOnInit(): void {
    this.quickTourVersionDate = this.quickTourService.getQuickTourVersionDate();
    this.quickTourSliderItems = this.quickTourService.getQuickTourSliderItems();
    this.openDialog();
  }

  public openDialog(): void {
    const dialogRef = this.dialog.open(QuickTourViewComponent, {
      disableClose: true,
      panelClass: 'custom-modal-box',
      backdropClass: 'custom-modal-box-background',
      data: {
        quickTourSliderItems: {...this.quickTourSliderItems}
      }
    });

    this.subscription.add(
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed && this.quickTourVersionDate) {
          this.quickTourService.saveUserPreferencesQuickTour(this.quickTourVersionDate);
          this.redirectToHomePage();
        }
      })
    );
  }

  public redirectToHomePage(): void {
    this.subscription.add(
      this.welcomeRedirectService.getFirstAvailableRoute$().subscribe({
        next: url => {
          this.welcomeRedirectService.directsToPath(url);
        },
        error: err => {
          this.welcomeRedirectService.directsToLoginPage({error: err.status, message: err.error, errorUrl: err.url});
        }
      })
    );
  }
}
