import {NgZone, inject} from '@angular/core';
import {CanActivateFn} from '@angular/router';
import {Observable} from 'rxjs';
import {WelcomeRedirectService} from '../services/redirect/redirect.service';

export const redirectGuard: CanActivateFn = (): Observable<boolean> | Promise<boolean> | boolean => {
  const welcomeRedirectService = inject(WelcomeRedirectService);
  const zone = inject(NgZone);

  if (!welcomeRedirectService.accessToken) {
    zone.run(() => {
      welcomeRedirectService.directsToLoginPage();
    });

    return false;
  }
  return true;
};
