import {ICapabilityUsage} from '../monitor.interface';

export const capabilityUsageResponseMock: ICapabilityUsage = {
  data: {
    dimensions: [1.6566336e12, 1.659312e12],
    categories: [
      {
        name: 'capability',
        values: [404.0, 288.0],
        series: [
          {
            name: 'USABILITY',
            total: 143.0,
            percentage: 0.20664739884393063,
            values: [137.0, 6.0]
          },
          {
            name: 'ADDRESS_VALIDATION',
            total: 74.0,
            percentage: 0.1069364161849711,
            values: [0.0, 74.0]
          },
          {
            name: 'PHONE_VERIFICATION',
            total: 73.0,
            percentage: 0.10549132947976879,
            values: [0.0, 73.0]
          },
          {
            name: 'LIVENESS',
            total: 56.0,
            percentage: 0.08092485549132948,
            values: [54.0, 2.0]
          },
          {
            name: 'SIMILARITY',
            total: 55.0,
            percentage: 0.07947976878612717,
            values: [52.0, 3.0]
          },
          {
            name: 'OTHER',
            total: 291.0,
            percentage: 0.42052023121387283,
            values: [32.0, 130.0]
          }
        ]
      }
    ],
    total: {
      value: 692.0,
      percentage: 692.0
    },
    average: {
      value: 346.0,
      percentage: 346.0
    }
  },
  context: {
    aggregatorFormat: 'month',
    monitorDataType: 'com.jumio.dashboardeventsaggregator.domain.CategoryMonitor',
    dimensionDataType: 'java.lang.Long',
    seriesDataType: 'java.lang.Double',
    startTime: 1654041600000,
    endTime: 1661731200000,
    historyStartTime: 1622505600000,
    historyEndTime: 1630195200000,
    durationInaggregatorFormat: 2,
    processedStartTime: 1663770866037,
    processedEndTime: 1663770866745,
    processedTime: 708
  }
};
