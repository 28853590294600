import {ITransactionUsage} from '../monitor.interface';

export const transactionUsageResponseMock: ITransactionUsage = {
  data: {
    categories: [
      {
        name: 'all',
        total: 2000.0,
        allotted: 50000.0,
        dateFrom: 1.635458368692e12,
        dateTo: 1.693259968695e12
      },
      {
        name: 'capability_EXTRACTION',
        total: 440.0,
        allotted: 40000.0,
        dateFrom: 1.635458368692e12,
        dateTo: 1.693259968695e12
      },
      {
        name: 'product_ID_IV',
        total: 30.0,
        allotted: 100.0,
        dateFrom: 1.635458368692e12,
        dateTo: 1.693259968695e12
      },
      {
        name: 'capability_ADDRESS_VALIDATION',
        total: 30000.0,
        allotted: 30000.0,
        dateFrom: 1.635458368692e12,
        dateTo: 1.693259968695e12
      },
      {
        name: 'product_DOCUMENT_VERIFICATION',
        total: 0,
        allotted: 0.0,
        dateFrom: 1.635458368692e12,
        dateTo: 1.693259968695e12
      }
    ]
  },
  context: {
    aggregatorFormat: 'day',
    monitorDataType: 'RadialBarCategoryMonitor',
    dimensionDataType: 'Long',
    seriesDataType: 'java.lang.Double',
    startTime: 1661385600000,
    endTime: 1661731200000,
    historyStartTime: 1629849600000,
    historyEndTime: 1630195200000,
    durationInaggregatorFormat: 4,
    processedStartTime: 1663358803814,
    processedEndTime: 1663358807064,
    processedTime: 3250
  }
};
