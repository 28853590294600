<form [formGroup]="formGroup">
  <ng-container formArrayName="array">
    <jtm-button
      class="pull-right"
      (clicked)="addItem()"
      [disabled]="disabled"
      [label]="addButtonLabel"
      [size]="buttonSizes.Medium"
      [variant]="buttonVariants.Secondary"
    >
      <i class="up-icon add-dark on-idp-button"></i>
    </jtm-button>

    <div class="row form-row" *ngFor="let item of formControls; let i = index">
      <div class="col-11">
        <app-form-group-input
          type="text"
          [id]="i"
          [name]="$any(i)"
          [formControlName]="i"
          [required]="true"
          [disabled]="disabled"
          (keydown.enter)="$event.preventDefault()"
          [customTrim]="true"
          [errorMessages]="errorMessages"
        ></app-form-group-input>
      </div>

      <div class="col-1">
        <button
          type="button"
          class="button hover-red pull-right"
          (click)="onRemoveButtonClick(i)"
          [disabled]="(required && onlyOneRowLeft()) || disabled"
          [title]="removeButtonTooltip()"
        >
          <i class="fas fa-times"></i>
        </button>
      </div>
    </div>
  </ng-container>
</form>
