import {
  AfterViewInit,
  Directive,
  DoCheck,
  ElementRef,
  EventEmitter,
  Input,
  KeyValueDiffer,
  KeyValueDiffers,
  OnDestroy,
  Output
} from '@angular/core';
import 'bootstrap-daterangepicker';
import $ from 'jquery';
import moment from 'moment';
import {DateRange} from './date-range.dto';

/**
 * @deprecated Use of this directive could cause problems because it is using jQuery to access HTML elements directly
 */
@Directive({
  selector: '[appDateRangeWrapper]',
  standalone: true
})
/**
 * A wrapper directive to set the options and register the event listeners of the daterangepicker library.
 * THe description of the datepicker library can be found here: http://www.daterangepicker.com/.
 */
export class DateRangeWrapperDirective implements AfterViewInit, OnDestroy, DoCheck {
  @Input() public options: any = {};
  @Input() public showTodayButton: boolean = false;
  @Input() public initialDaterange: DateRange | undefined;
  @Output() public selected = new EventEmitter<ActiveRange>();
  @Output() public cancelDaterangepicker = new EventEmitter();
  @Output() public applyDaterangepicker = new EventEmitter();
  @Output() public hideCalendarDaterangepicker = new EventEmitter();
  @Output() public showCalendarDaterangepicker = new EventEmitter();
  @Output() public hideDaterangepicker = new EventEmitter();
  @Output() public showDaterangepicker = new EventEmitter();
  @Output() public outsideClick = new EventEmitter();

  @Input() public set currentDaterange(value: ActiveRange) {
    this._currentDaterange = value;
    if (value) {
      this.activeRange = value;
    }
  }

  public get currentDaterange(): ActiveRange {
    //@ts-ignore
    return this._currentDaterange;
  }

  private _currentDaterange: ActiveRange | undefined;
  private activeRange: ActiveRange | undefined;
  private differ: {options?: KeyValueDiffer<unknown, unknown>} = {};
  private datePicker: any;

  /**
   * Initializes a new differ to check whether there was a change in options.
   */
  constructor(private elementRef: ElementRef, keyValueDiffers: KeyValueDiffers) {
    this.differ['options'] = keyValueDiffers.find(this.options).create();
  }

  ngAfterViewInit(): void {
    this.render();

    /**
     * if the datepicker is used insie the custom dropdown component, it gets re-created every time the dropdown is opened
     * so it can't store the previously selected date range, so we need to maunally set the start and end dates
     */
    if (this.initialDaterange) {
      this.setInitialDaterange();
    }

    /**
     * stops click events from escaping the scope of the daterangepicker dom element
     * it is required when used as an element of a dropdown component to stop the dropdown from closing itself
     */
    $('.daterangepicker').on('click', ev => {
      ev.stopPropagation();
    });
  }

  /**
   * Runs on Angular check cycles.
   * Checks whether the options input has changed since the last cycle.
   * If they changed, re-renders the component and re-attaches the event listners.
   */
  ngDoCheck(): void {
    //@ts-ignore
    const optionsChanged = this.differ['options'].diff(this.options);
    if (optionsChanged) {
      this.render();
      if (this.showTodayButton) {
        this.insertTodayButton();
      }
      this.attachEventListeners();
      if (this.activeRange && this.datePicker) {
        this.datePicker.setStartDate(this.activeRange.start);
        this.datePicker.setEndDate(this.activeRange.end);
      }
    }
  }

  ngOnDestroy(): void {
    try {
      ($(this.elementRef.nativeElement) as any).data('daterangepicker').remove();
    } catch (error: any) {
      console.warn('Daterangepicker error:', error.message);
    }
  }

  public resetDateRange(): void {
    this.render();
  }

  /**
   * Sets the options of the daterange picker based on the options given in the Angular input.
   * Gets the datepicker object from the native element.
   */
  private render(): void {
    const options = {...this.options};
    ($(this.elementRef.nativeElement) as any).daterangepicker(options, this.callback.bind(this));
    this.datePicker = ($(this.elementRef.nativeElement) as any).data('daterangepicker');
  }

  /**
   * Set the pre-selected date range if passed to the directive
   */
  private setInitialDaterange(): void {
    const parentEl = $(this.elementRef.nativeElement) as any;
    parentEl.data('daterangepicker').setStartDate(this.initialDaterange?.from);
    parentEl.data('daterangepicker').setEndDate(this.initialDaterange?.to);
  }

  /**
   * Registers all necessary event listeners of the daterange picker.
   */
  private attachEventListeners(): void {
    this.addEventListener('show', this.showDaterangepicker);
    this.addEventListener('hide', this.hideDaterangepicker);
    this.addEventListener('cancel', this.cancelDaterangepicker);
    this.addEventListener('apply', this.applyDaterangepicker);
    this.addEventListener('hideCalendar', this.hideCalendarDaterangepicker);
    this.addEventListener('showCalendar', this.showCalendarDaterangepicker);
    this.addEventListener('outsideClick', this.outsideClick);
  }

  private insertTodayButton(): void {
    $(`#${this.elementRef.nativeElement.parentNode.id}`)
      .find('.drp-buttons .cancelBtn')
      .before('<button type="button" class="todayBtn btn btn-sm btn-info">Today</button>');
    $('.todayBtn').click(() => {
      this.datePicker.setEndDate(null);
      this.datePicker.setStartDate(moment.utc().startOf('day').toDate());
      this.datePicker.updateView();
    });
  }

  /**
   * Adds a daterange picker event listener based on the event code.
   */
  private addEventListener(eventCode: string, eventEmitter: EventEmitter<any>): void {
    $(`#${this.elementRef.nativeElement.parentNode.id}`).on(`${eventCode}.daterangepicker`, (event, picker) => {
      const e = {
        event,
        picker
      };
      eventEmitter.emit(e);
    });
  }

  /**
   * A callback function which will be called when the value of the daterange picker changes.
   * In this case, a new activerange object will be created and an output emitted.
   */
  private callback(start?: any, end?: any, label?: string): void {
    this.activeRange = this.getActiveRange(start, end, label);
    this.selected.emit(this.activeRange);
  }

  /**
   * Create new activerange object
   */
  private getActiveRange(start?: any, end?: any, label?: string): ActiveRange {
    return new ActiveRange(moment(start).utc(true), moment(end).utc(true).endOf('day'), label);
  }
}

/**
 * A helper class that contains the daterange object selected with the daterange picker library.
 */
export class ActiveRange {
  constructor(public start?: moment.Moment, public end?: moment.Moment, public label?: string) {}
}
