import {IChannelSource} from '../monitor.interface';

export const channelSourceResponseMock: IChannelSource = {
  data: {
    categories: [
      {
        name: 'SDK',
        percentage: 0.10286844708209693,
        value: 104.0
      },
      {
        name: 'API',
        percentage: 0.8021760633036598,
        value: 811.0
      },
      {
        name: 'WEB',
        percentage: 0.09495548961424333,
        value: 96.0
      }
    ]
  },
  context: {
    aggregatorFormat: 'month',
    monitorDataType: 'com.jumio.dashboardeventsaggregator.domain.DoughnutCategoryMonitor',
    dimensionDataType: 'java.lang.Long',
    seriesDataType: 'java.lang.Double',
    startTime: 1633478400000,
    endTime: 1664928000000,
    historyStartTime: 1601942400000,
    historyEndTime: 1633392000000,
    durationInaggregatorFormat: 12,
    processedStartTime: 1665075951384,
    processedEndTime: 1665075951778,
    processedTime: 394
  }
};
