import {ICases} from '../monitor.interface';

export const yourCasesResponseMock: ICases = {
  data: {
    open: 20,
    inProgress: 5,
    closed: 7,
    inReview: 2
  },
  context: {
    aggregatorFormat: 'day',
    monitorDataType: 'com.jumio.dashboardeventsaggregator.domain.CategoryMonitor',
    dimensionDataType: 'java.lang.Long',
    seriesDataType: 'java.lang.Double',
    startTime: 1661385600000,
    endTime: 1661731200000,
    historyStartTime: 1629849600000,
    historyEndTime: 1630195200000,
    durationInaggregatorFormat: 4,
    processedStartTime: 1663640878564,
    processedEndTime: 1663640880372,
    processedTime: 1808
  }
};
