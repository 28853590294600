<section class="quick-tour-modal">
  <section class="modal-card-body">
    <div
      class="modal-content-left"
      [ngStyle]="{
        'background-image': 'url(/welcome/' + quickTourSliderItems[currentPanel].image + ')',
        'background-position': 'left',
        'background-size': 'cover'
      }"
    ></div>
    <div class="modal-content-right">
      <div class="modal-content-body">
        <h5>{{ quickTourSliderItems[currentPanel].heading }}</h5>
        <h2>{{ quickTourSliderItems[currentPanel].title }}</h2>
        <h4>{{ quickTourSliderItems[currentPanel].subHeading }}</h4>
        <div class="modal-body-text">
          <div class="modal-text-list" *ngFor="let items of quickTourSliderItems[currentPanel]?.bodyTextItems; first as isFirst">
            <div class="modal-text-list-item" [ngStyle]="isFirst ? {'padding-bottom': '24px'} : {'padding-bottom': '32px'}">
              <h4>{{ items.title }}</h4>
              {{ items.item }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <footer class="modal-card-footer">
    <div>
      <jtm-button [label]="quickTourSliderItems[currentPanel].labelButton" (clicked)="closeModal()"></jtm-button>
    </div>
  </footer>
</section>
