import {Component, ErrorHandler, HostBinding, Renderer2} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivationEnd, Router} from '@angular/router';
import {Permission, UntilDestroy} from '@jumio/portals.core';
import {ErrorDescriptor} from 'public-shared/models/error/error-descriptor';
import {Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';
import {J4ErrorHandler} from '../../services/error-handler';
import {RedirectService} from '../../services/redirect/redirect.service';

@UntilDestroy()
@Component({
  selector: 'j4-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.less']
})
export class RootComponent {
  @HostBinding('@.disabled')
  /**
   * If true, all animations will be disabled in the application.
   */
  public serviceError: ErrorDescriptor | null | undefined;

  public readonly subscription = new Subscription();
  public readonly Permission = Permission;
  public readonly TITLE_POSTFIX = ' - Jumio Portal';

  constructor(
    private redirectService: RedirectService,
    private router: Router,
    title: Title,
    private errorHandler: ErrorHandler,
    private renderer: Renderer2
  ) {
    //Check if user is loggedIn and redirects to login page if is not
    if (this.redirectService.shouldRedirectToLogin()) {
      this.redirectService.redirectToLoginPage();
    } else {
      this.subscription.add(
        this.router.events
          .pipe(filter(event => event instanceof ActivationEnd && event?.snapshot?.children.length === 0))
          .subscribe(event => {
            const titleParam = (event as ActivationEnd).snapshot.data.title ?? '';
            title.setTitle(`${titleParam}${this.TITLE_POSTFIX}`);

            if (this.serviceError) {
              this.serviceError = null;
            }

            try {
              const loader = this.renderer.selectRootElement('#pre-load-spinner');
              this.renderer.setStyle(loader, 'display', 'none');
            } catch (e) {
              // Do nothing
            }
          })
      );

      if (this.errorHandler instanceof J4ErrorHandler) {
        this.subscription.add(
          this.errorHandler.unavailableServiceSubject.subscribe((error: ErrorDescriptor) => {
            this.serviceError = error;
          })
        );
      }
    }
  }
}
