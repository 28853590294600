import {IDocumentTypeTransactions} from '../monitor.interface';

export const documentTypeTransactionsResponseMock: IDocumentTypeTransactions = {
  data: {
    dimensions: [1656633600000, 1659312000000],
    categories: [
      {
        name: 'document',
        values: [24, 1],
        series: [
          {
            name: 'DRIVING_LICENSE',
            total: 24,
            percentage: 0.96,
            values: [23, 2]
          },
          {
            name: 'ID_CARD',
            total: 1,
            percentage: 0.04,
            values: [1, 0]
          }
        ]
      }
    ],
    total: {
      value: 25,
      percentage: 25
    },
    average: {
      value: 12,
      percentage: 12
    }
  },
  context: {
    aggregatorFormat: 'month',
    monitorDataType: 'com.jumio.dashboardeventsaggregator.domain.CategoryMonitor',
    dimensionDataType: 'java.lang.Long',
    seriesDataType: 'java.lang.Double',
    startTime: 1657497600,
    endTime: 1662681600,
    historyStartTime: 1625961600000,
    historyEndTime: 1631145600000,
    durationInaggregatorFormat: 2,
    processedStartTime: 1662777318437,
    processedEndTime: 1662777318985,
    processedTime: 548
  }
};
