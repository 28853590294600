import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {JtmHttpInterceptor} from '@jtm/jtm-services';
import {J4HttpInterceptor} from '../../services/http-interceptor';

@NgModule({
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JtmHttpInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: J4HttpInterceptor,
      multi: true
    }
  ]
})
export class CoreJ4InterceptorsModule {}
