import {APP_BASE_HREF} from '@angular/common';
import {Component, OnInit, ViewEncapsulation, inject} from '@angular/core';
import {MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';

@Component({
  selector: 'j4-quick-tour-view',
  templateUrl: './quick-tour-view.component.html',
  styleUrls: ['./quick-tour-view.component.less'],
  // eslint-disable-next-line @angular-eslint/use-component-view-encapsulation
  encapsulation: ViewEncapsulation.None
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class QuickTourViewComponent implements OnInit {
  protected baseHref = inject(APP_BASE_HREF);
  private dialogRef: MatDialogRef<QuickTourViewComponent> = inject(MatDialogRef);
  private data: {quickTourSliderItems: IQuickTourSliderItem[]} = inject(MAT_DIALOG_DATA);
  public currentPanel = 0;
  public quickTourSliderItems: IQuickTourSliderItem[] = [];

  ngOnInit(): void {
    this.quickTourSliderItems = this.data.quickTourSliderItems;
  }

  public closeModal(): void {
    this.dialogRef.close(true);
  }
}

interface IQuickTourSliderItem {
  image: string;
  heading: string;
  title: string;
  subHeading: string;
  bodyTextItems: {
    title: string;
    item: string;
  }[];
  labelButton: string;
}
