import {
  AfterContentInit,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  ViewChild
} from '@angular/core';
import * as _ from 'lodash-es';
import {JumioPageFilter} from 'shared/components/table/dto/jumio-page-filter';
import {JumioSort} from 'shared/components/table/dto/jumio-sort';
import {DataTableColumnDirective} from './data-table-column.directive';
import {TableViewEnum} from './table-view.enum';
import {ToggleAll} from './toggle/toggle.helper';
import {TableColumnProp} from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.less']
})
export class DataTableComponent<T> implements AfterContentInit, OnInit, OnChanges {
  @Input() public filter: JumioPageFilter | undefined;
  @Input() public items: T[] | undefined;
  @Input() public maxItemCount: number | undefined;
  @Input() public useClientPagination = false;
  @Input() public hasNavigation = false;
  @Input() public view: TableViewEnum = TableViewEnum.TABLE;

  @Output()
  public onFilterChange = new EventEmitter<void>();
  @Output()
  public onRowClick = new EventEmitter<T>();

  @ContentChildren(DataTableColumnDirective) public columns: QueryList<DataTableColumnDirective> | undefined;
  @ViewChild('container', {static: true}) public container: ElementRef | undefined;

  public resizeTimer: any;
  public allItems: T[] | undefined;

  @HostListener('window:resize')
  public onResize(): void {
    clearTimeout(this.resizeTimer);
    this.resizeTimer = setTimeout(() => {
      this.adjustColumnSize();
    }, 250);
  }

  public isListView(): boolean {
    return this.view === TableViewEnum.TABLE;
  }

  public isCardView(): boolean {
    return this.view === TableViewEnum.CARD;
  }

  public isHeaderVisible(): boolean {
    return this.isListView();
  }

  public ngOnInit(): void {
    this.allItems = this.items;
    if (this.useClientPagination) {
      this.maxItemCount = this.allItems?.length;
      this.filter = this.filter || new JumioPageFilter();
      this.filter.pageSize = this.filter.pageSize || 20;
      this.filter.sort = new JumioSort('', true);
      this.paginateItems();
    }
  }

  public ngOnChanges(): void {
    this.allItems = this.items;
    if (this.useClientPagination) {
      this.maxItemCount = this.allItems?.length;
      if (this.filter) {
        this.paginateItems();
      }
    }
  }

  public ngAfterContentInit(): void {
    this.adjustColumnSize();
  }

  public adjustColumnSize(): void {
    const totalWidth = this.container?.nativeElement.offsetWidth;
    const colSizes = this.columns?.reduce(
      (total, col) => {
        if (col.colWidth) {
          total.fixWidth += col.colWidth;
          return total;
        } else {
          total.totalWidthPortions += col.colRatio;
          return total;
        }
      },
      {fixWidth: 0, totalWidthPortions: 0}
    );

    //@ts-ignore
    const dynamicWidth = totalWidth - colSizes.fixWidth;
    this.columns?.forEach(col => {
      if (!col.colWidth) {
        //@ts-ignore
        col.calculatedPercentage = Math.floor((((col.colRatio / colSizes.totalWidthPortions) * dynamicWidth) / totalWidth) * 100);
      }
    });
  }

  public rowClick(item: T): void {
    this.onRowClick.next(item);
  }

  public pageChanged(): void {
    if (this.useClientPagination) {
      this.paginateItems();
    } else {
      this.onFilterChange.next();
    }
  }

  public changeSort(sortCol: TableColumnProp | undefined): void {
    if (!sortCol) {
      return;
    }

    if (sortCol === this.filter?.sort?.field) {
      //@ts-ignore
      this.filter.sort.asc = !this.filter.sort.asc;
    } else {
      //@ts-ignore
      this.filter.sort = new JumioSort(sortCol, true);
    }
    //@ts-ignore
    this.filter.offset = 0;
    this.onFilterChange.next();
    if (this.useClientPagination) {
      this.sortItems();
    }
  }

  public changeToggleAll(toggleAll: ToggleAll): void {
    //@ts-ignore
    this.toggleItems(toggleAll, this.allItems);
  }

  public changeToggleCurrentPage(toggle: ToggleAll): void {
    // Expected behaviour:
    // *Unselect toggle* - `!toggle.status === false` - un-selects all items, from all pages
    // *Select toggle* - `!toggle.status === true` - selects only the items on the current page
    const itemsToChange = !toggle.status ? this.items : this.allItems;

    //@ts-ignore
    this.toggleItems(toggle, itemsToChange);
  }

  private toggleItems(toggle: ToggleAll, items: T[]): void {
    toggle.status = !toggle.status;

    items
      // Filter elements which don't apply to the toggle condition
      .filter(i => (toggle.condition && toggle.condition(i)) || !toggle.condition)
      //@ts-ignore
      .forEach(item => (item[toggle.key] = toggle.status));
  }

  private sortItems(): void {
    this.items = this.allItems?.sort((a: T, b: T) => {
      //@ts-ignore
      if (_.get(a, this.filter.sort.field) < _.get(b, this.filter.sort.field)) {
        //@ts-ignore
        return this.filter.sort.asc ? -1 : 1;
        //@ts-ignore
      } else if (_.get(a, this.filter.sort.field) > _.get(b, this.filter.sort.field)) {
        //@ts-ignore
        return this.filter.sort.asc ? 1 : -1;
      } else {
        return 0;
      }
    });
    //@ts-ignore
    this.filter.offset = 0;
    this.paginateItems();
  }

  private paginateItems(): void {
    //@ts-ignore
    this.items = this.allItems.slice(this.filter.offset, this.filter.offset + this.filter.pageSize);
  }
}
