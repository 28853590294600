<div class="error-page">
  <jtm-navigation-J4></jtm-navigation-J4>
  
  <div class="error-container">
    <div class="error-title">Something went wrong</div>
    <br>
    <br>
    <div class="error-msg"*ngIf="displayNoAccessToTenantError">User doesn't have access to the tenant.</div>
  </div>
</div>
