import {Component} from '@angular/core';
import {JtmComponentsModule} from '@jtm/jtm-components';

@Component({
  selector: 'j4-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.less'],
  standalone: true,
  imports: [JtmComponentsModule]
})
export class NavigationComponent {}
