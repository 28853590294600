import {Component, EventEmitter, Injectable, Input, Output, QueryList} from '@angular/core';
import {TableColumnProp} from '@swimlane/ngx-datatable';
import {JumioSort} from 'shared/components/table/dto/jumio-sort';
import {DataTableColumnDirective} from '../data-table-column.directive';

@Component({
  template: ''
})
@Injectable()
export class ViewComponent {
  @Input() public columns: QueryList<DataTableColumnDirective> | undefined;
  @Input() public items: any[] | undefined;
  @Input() public hasNavigation = false;
  @Input() public sortedField: TableColumnProp | undefined;

  @Output() public rowClick = new EventEmitter<JumioSort>();
  public isMacOS = window.navigator.platform === 'MacIntel';

  public onItemClick(item: any): void {
    this.rowClick.emit(item);
  }

  public isCurrentSort(col: DataTableColumnDirective): boolean | undefined {
    if (col) {
      return col.sort === this.sortedField;
    }
    return;
  }
}
