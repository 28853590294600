import {HttpBackend, HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable, inject} from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {FF_BASE_API_URL, FeatureFlags} from '../../constants/feature-flags.constants';

@Injectable()
export class FeatureFlagConfigService {
  private baseUrl: string = inject(FF_BASE_API_URL);

  // Bypass external HttpInterceptor, keeping the url logic coupled in FeatureFlag lib
  protected handler = inject(HttpBackend);
  private http = new HttpClient(this.handler);

  public loadFeatureFlagConfig$(): Observable<FeatureFlags> {
    return this.get$<FeatureFlags>('/feature-flags/feature-flag.json').pipe(
      catchError(error => {
        console.error('Feature Flag config not available:', error);
        return of({} as FeatureFlags);
      })
    );
  }

  private get$<T>(url: string, headers?: HttpHeaders, params?: HttpParams | {[param: string]: string | string[]}): Observable<T> {
    return this.http.get<T>(this.baseUrl + url, {headers, params});
  }
}
