import {Component, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {FormGroupConstants} from 'public-shared/components/abstract-form/form-group.constants';
import {ErrorDescriptor} from 'public-shared/models/error/error-descriptor';
import {Subscription} from 'rxjs';
import {ModalComponent} from '@jumio/portals.core-ui';
import {SecurityContextHolder} from 'shared/services/security/security-context-holder';
import {PasswordOrConfirmModalService} from '../../services/password-or-confirm-modal.service';
import {SharedModule} from 'shared/shared.module';
import {FormGroupModule} from '../form-group/form-group.module';
import {UntilDestroy} from '@jumio/portals.core';

@UntilDestroy()
@Component({
  selector: 'app-password-or-confirm-modal',
  templateUrl: './password-modal.component.html',
  styleUrls: ['./password-modal.component.less'],
  standalone: true,
  imports: [SharedModule, FormGroupModule]
})
export class PasswordModalComponent implements OnInit {
  @ViewChild('modal') public modal: ModalComponent | undefined;
  @ViewChild(NgForm) public ngForm: NgForm | undefined;

  public message?: string;
  public password?: string;
  public passCode?: string;
  public isPending = false;
  public isTfaEnabled?: boolean | null;
  public readonly subscription = new Subscription();

  constructor(public service: PasswordOrConfirmModalService, private securityContextHolder: SecurityContextHolder) {}

  ngOnInit(): void {
    this.subscription.add(
      this.service.state.subscribe(stateMessage => {
        this.isPending = false;
        if (!stateMessage) {
          this.modal?.close();
        } else {
          this.message = stateMessage.message;
          this.isTfaEnabled = this.securityContextHolder.isTfaEnabled;
          this.modal?.show();
        }
      })
    );

    this.subscription.add(
      this.service.error.subscribe((error: ErrorDescriptor) => {
        this.isPending = false;
        if (error.errorCode === '5001') {
          this.ngForm?.form.controls.password.setErrors({[FormGroupConstants.WRONG_PASSWORD_ERROR]: true});
        } else if (error.errorCode === '2001') {
          this.ngForm?.form.controls.passCode.setErrors({[FormGroupConstants.WRONG_SECURITYPASS_ERROR]: true});
        }
      })
    );
  }

  public closeModal(): void {
    this.modal?.close();
  }

  public resetForm(): void {
    this.ngForm?.reset();
  }

  public submitPassword(): void {
    if (this.password && this.password.length) {
      this.isPending = true;
      this.service.data.next({
        password: this.password,
        passCode: this.passCode ? this.passCode : null
      });
    }
  }

  public submit(): void {
    this.isPending = true;
    this.service.data.next({});
  }

  public get isSSOUser(): boolean {
    return !!this.securityContextHolder.refreshToken;
  }

  public get errorMessages(): Record<'required' | 'wrongPassword' | 'wrongpassCode', string> {
    return {required: 'This field is required', wrongPassword: 'Incorrect password!', wrongpassCode: 'Incorrect security code!'};
  }
}
