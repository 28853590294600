import {ErrorHandler, Injectable, Injector, NgZone} from '@angular/core';
import {JtmSecurityContextHolder} from '@jtm/jtm-services';
import {ErrorDescriptor} from 'public-shared/models/error/error-descriptor';
import {EnvironmentProvider} from 'public-shared/services/environment-provider';
import {LoggerService} from 'public-shared/services/logging/logger.service';
import {NotificationService} from 'public-shared/services/notification/notification.service';
import {Subject} from 'rxjs';
import {CustomerEnvironmentData} from '@jumio/portals.core';
import {ErrorCodes} from 'shared/services/error/error-codes';
import {HttpResponseCodes} from './constants';

@Injectable()
/**
 * Generic error handler service.
 */
export class J4ErrorHandler implements ErrorHandler {
  public unavailableServiceSubject: Subject<ErrorDescriptor>;
  /**
   * The last error that occured in the app.
   */
  public error: ErrorDescriptor | undefined;

  constructor(
    private loggerService: LoggerService,
    private notificationService: NotificationService,
    private injector: Injector,
    private securityContextHolder: JtmSecurityContextHolder,
    private zone: NgZone,
    private environmentProvider: EnvironmentProvider<CustomerEnvironmentData>
  ) {
    this.unavailableServiceSubject = new Subject<ErrorDescriptor>();
  }

  /**
   * Converts generic error to ErrorDescriptor type which can be handled.
   * @param error The error input that can be of any type.
   * @returns {any} Returns the error converted into an ErrorDescriptor object.
   */
  public static convertToErrorDescriptor(error: unknown): ErrorDescriptor {
    if (error instanceof ErrorDescriptor) {
      return error;
    } else {
      return ErrorDescriptor.unknownError(error);
    }
  }

  /**
   * Error handling function. Converts the error to to an ErrorDescriptor, then navigates the user to the appropriate page.
   * @param error The current error input.
   */
  public handleError(error: any): void {
    console.error('Error found', error);
    this.error = J4ErrorHandler.convertToErrorDescriptor(error);

    if (this.error.isTokenInvalid || this.error.status === HttpResponseCodes.UNAUTHORIZED) {
      this.notificationService.warn("401 Unauthorized. You don't have the required permission to view this page.");
    } else if (this.error.status === HttpResponseCodes.NOT_FOUND) {
      this.notificationService.warn('Not found. The requested page might have been removed or moved to another destination.');
    } else if (this.error.status === HttpResponseCodes.FORBIDDEN) {
      this.notificationService.warn("Unauthorized. You don't have the required permission to view this page.");
    } else if (
      this.environmentProvider.environment.handleUnavailableServiceError &&
      this.error.errorCode === ErrorCodes.SERVICE_UNAVAILABLE
    ) {
      this.unavailableServiceSubject.next(this.error);
    } else {
      this.notificationService.error(this.error);
    }
    this.logError();
  }

  /**
   * Sends a logging request to the backend.
   * @param {ErrorDescriptor} error The current error that needs to be logged.
   */
  public logError(): void {
    // TODO: -  https://jira.int.jumio.com/browse/PORT-321
    // this.loggerService.addError(error).subscribe(
    //   () => {},
    //   e => {
    //     console.error('Error on log service!', e);
    //   }
    // );
  }
}
