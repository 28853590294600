import {Inject, Injectable} from '@angular/core';
import {datadogRum} from '@datadog/browser-rum';
import {getCurrentTenantName, getCurrentUser} from '@jumio/portals.core';
import {BehaviorSubject, Observable, Subscription, interval} from 'rxjs';
import {distinctUntilChanged, filter, map, switchMap, take} from 'rxjs/operators';
import {DATADOG_RUM_CONFIG, PortalDatadogIdentifier} from '../constants/datadog-init.constants';
import {areCookiesAllowed, getAccessToken} from '../helpers/local-storage/local-storage.helper';
import {IDataDogConfig, IInternalDataDogConfig, ILogContext} from '../interfaces/config.interface';
import {IDatadogRumConfig} from '../interfaces/datadog-config.interface';
import {ConfigService} from './http-services/config.service';

@Injectable()
export class DatadogRumLoggerService {
  public areCookiesAllowed = areCookiesAllowed();

  public readonly cookiesAccepted$ = new BehaviorSubject(this.areCookiesAllowed);
  private readonly subscription = new Subscription();

  constructor(@Inject(DATADOG_RUM_CONFIG) protected config: IDatadogRumConfig, protected configService: ConfigService) {}

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  // Method should be called on app root onInit - can't be called inside service constructor
  // https://github.com/angular/angular/issues/25590
  public load(): void {
    const obs$ = this.getDatadogConfig$();

    this.subscription.add(
      obs$.subscribe(config => {
        if (config.enabled && config.setup) {
          this.init(config.setup);
        }
      })
    );
  }

  // Log Custom Events
  public logAction(eventName: string, context?: ILogContext, appName: string = this.config.service): void {
    const event = `${appName} | ${eventName}`;
    const defaultString = 'Not available';
    const currentUser = getCurrentUser();

    const metadata = {
      common: {
        user: currentUser.email || defaultString,
        userId: currentUser.userId || defaultString,
        tenantId: currentUser.tenantId || defaultString,
        tenantName: getCurrentTenantName() || defaultString
      },
      app: context
    };
    datadogRum.addAction(event, metadata);
  }

  private init(config: IDataDogConfig): void {
    config.service = this.config.service;
    config.version = this.config.appVersion || 'not-specified';
    config.trackInteractions = false;
    datadogRum.init(config);
  }

  private getDatadogConfig$(): Observable<Partial<IInternalDataDogConfig>> {
    const checkTokenExists$ = interval(1000).pipe(
      map(() => !!getAccessToken()),
      filter(tokenExists => !!tokenExists),
      take(1)
    );

    const fetchDatadogConfig$ = this.configService.datadogConfig$(this.config.portalIdentifier ?? PortalDatadogIdentifier.UNIFIED_PORTAL);

    const checkCookiesAccepted$ = this.cookiesAccepted$.pipe(
      distinctUntilChanged((prev, cur) => prev === cur), // Only if the cookiesConsent value changed
      filter(cookiesAccepted => cookiesAccepted),
      switchMap(() => fetchDatadogConfig$)
    );

    // If this.config.ignoreCookies === true - doesn't need to check cookies or token
    if (!!this.config.ignoreCookies) {
      return fetchDatadogConfig$;
    }

    /**
     * If this.config.ignoreCookies === false - need to check cookies
     */

    // Check if needs to wait for token
    if (!!this.config.waitForToken) {
      // If needs to wait for the token, then it should only fetch DD config, after token is available
      return checkTokenExists$.pipe(
        filter(tokenExists => tokenExists),
        switchMap(() => checkCookiesAccepted$)
      );
    }

    // Otherwise, simply fetch DD config in case cookies are accepted
    return checkCookiesAccepted$;
  }
}
