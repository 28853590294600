import {Provider} from '@angular/core';
import {CustomerEnvironmentData} from '@jumio/portals.core';
/**
 * Development environment configuration.
 */
export const environment: J4WelcomeEnvironmentData = {
  production: true,
  apiUrl: () => window.location.origin.concat('/api/'),
  apiBaseUrl: window.location.origin,
  loginUrl: 'https://portal.jumio.link/login',
  mainUrl: '/',
  defaultRedirectUrl: '/dashboard',
  idleInterval: 15 * 60 * 1000,
  deployUrl: '/static/welcome/',
  idleIntervalEnabled: false,
  infoComponentDuration: 8000,
  scrollToTopEnabled: true,
  handleUnavailableServiceError: true,
  animationEnabled: true,
  // CustomerEnvironmentData items:
  menuItems: [],
  isJ4CustomerPortalEnabled: true
};

export interface J4WelcomeEnvironmentData extends CustomerEnvironmentData {
  apiBaseUrl: string;
  mainUrl: string;
  defaultRedirectUrl: string;
  enableTracing?: boolean;
}
export const ENV_SPECIFIC_PROVIDERS: Provider[] = [];
