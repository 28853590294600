import {IWidgetItem} from './monitor.interface';

export function createCalculatedWidth(widgetItem: IWidgetItem): IWidgetItem {
  return {
    ...widgetItem,
    data: {
      ...widgetItem.data,
      calculatedWidth: `width:${widgetItem.cols * 255 + (widgetItem.cols - 1) * 16}px`
    }
  };
}
