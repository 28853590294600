<ng-container>
  <div class="display-flex">
    <app-notification></app-notification>
    <j4-navigation></j4-navigation>
    <div class="main-container">
      <router-outlet></router-outlet>
    </div>
  </div>
  <app-unsaved-changes-modal></app-unsaved-changes-modal>
  <app-password-or-confirm-modal></app-password-or-confirm-modal>
</ng-container>
